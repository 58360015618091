import React, { useState } from "react";
import { FormModal } from "@sciencecorp/helix-components";
import { FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";

import { useCreateCredentialPackage } from "../../../../api/credential_packages";

type NewCredentialPackageModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const NewCredentialPackageModal = ({ isOpen, onClose }: NewCredentialPackageModalProps) => {
  const { mutate: createCredentialPackage, isLoading } = useCreateCredentialPackage();
  const [name, setName] = useState<string>("");
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = () => {
    if (name) {
      createCredentialPackage(
        {
          name,
        },
        {
          onSuccess: (cp) => {
            handleClose();
            navigate(`/credential_packages/${cp.id}`);
          },
          onError: () => {
            toast({
              title: "Error",
              description: "Failed to create credential package",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    }
  };

  const handleClose = () => {
    setName("");
    onClose();
  };

  return (
    <FormModal
      title="Create New Package"
      submitButtonColorSchema="teal"
      closeOnOverlayClick={false}
      submitButtonTitle="Submit"
      size="lg"
      isOpen={isOpen}
      submitButtonDisabled={!name}
      isLoading={isLoading}
      onClose={handleClose}
      handleSubmit={onSubmit}>
      <FormControl>
        <FormLabel>Credential Package Name</FormLabel>
        <Input
          placeholder="Type Credential Package Name"
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
    </FormModal>
  );
};
