import { Flex, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { UserSelect } from "../../../Users/shared/UserSelect";
import { useBulkCreateInventoryUser } from "../../../../api/inventory";
import { UserMinimalData } from "../../../../api/user";

type EditableInventoryAssociationProps = {
  inventoryId: number;
  isOpen: boolean;
  onClose: () => void;
  inventoryUsers: UserMinimalData[];
};

export const EditableInventoryAssociation = ({
  inventoryId,
  inventoryUsers,
  isOpen,
  onClose,
}: EditableInventoryAssociationProps) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      owners: inventoryUsers.map((user) => user.id),
    },
  });
  const toast = useToast();

  const { mutateAsync: bulkCreateInventoryUser, isLoading: isLoadingBulkCreateInventoryUser } =
    useBulkCreateInventoryUser();
  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = () => {
    if (formValues.owners) {
      bulkCreateInventoryUser({
        inventory_id: inventoryId,
        user_ids: formValues.owners,
      })
        .then(() => {
          toast({
            title: "Owners updated",
            status: "success",
          });
          handleClose();
        })
        .catch((error) => {
          toast({
            title: "Error updating owners",
            description: error.message,
            status: "error",
          });
        });
    }
  };

  return (
    <FormModal
      size={"md"}
      title={"Edit Owners"}
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      closeOnOverlayClick={false}
      isLoading={isLoadingBulkCreateInventoryUser}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="owners"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Who should own this inventory?</FormLabel>
              <UserSelect
                setValue={(value) => setValue("owners", value)}
                alreadyAddedUsers={inventoryUsers}
              />
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};
