import { InventoryItemCheckoutData } from "../../api/inventory_item";
import { Money } from "../../helpers/Money";

export const InventoryCategoryOptions = [
  { label: "Chemicals & Reagents", value: "chemicals_reagents" },
  { label: "Electronics", value: "electronics" },
  { label: "Prototyping Consumables", value: "prototyping_consumables" },
  { label: "Facilities Management", value: "facilities_management" },
  { label: "Laboratory Equipment", value: "laboratory_equipment" },
  { label: "Medical Supplies", value: "medical_supplies" },
  { label: "Office Supplies", value: "office_supplies" },
  { label: "Precious Metals", value: "precious_metals" },
  { label: "Software & IT", value: "software_it" },
  { label: "Miscellaneous", value: "miscellaneous" },
  { label: "PPE", value: "ppe" },
  { label: "Lab Consumables", value: "lab_consumables" },
  { label: "Device Consumables", value: "device_consumables" },
  { label: "Wafers", value: "wafers" },
  { label: "Gas", value: "gas" },
  { label: "VOA", value: "voa" },
  { label: "Part", value: "part" },
];

export const inventoryStatusColor = (status: string) => {
  switch (status) {
    case "in_stock":
      return "green";
    case "low_stock":
      return "yellow";
    case "out_of_stock":
      return "red";
  }
  return "gray";
};

export const calculateCostAndItemsUsed = (
  amountCheckedOut: number,
  inventoryItems: InventoryItemCheckoutData[],
  currency: string
): {
  totalCost: Money;
  itemsUsed: { id: number; quantity_used: number }[];
} => {
  let remainingAmount = amountCheckedOut;
  let totalCost = Money.zero(currency);

  const itemsUsed: { id: number; quantity_used: number }[] = [];

  for (const item of inventoryItems) {
    const { total_quantity, amount, consumable_summary, id } = item;

    if (remainingAmount <= 0) break;

    // Calculate how much this item can contribute

    const remainingQuantity = consumable_summary.remaining_consumable_quantity;
    const quantityToUse = Math.min(remainingAmount, remainingQuantity);

    const itemCost = amount.div(total_quantity).times(quantityToUse);

    // Add to total cost
    totalCost = totalCost.add(itemCost);

    // Reduce the remaining amount to be checked out
    remainingAmount -= quantityToUse;
    itemsUsed.push({ id, quantity_used: quantityToUse });
  }

  return { totalCost, itemsUsed };
};
