import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Heading,
  Flex,
  Alert,
  AlertTitle,
  AlertDescription,
  useColorModeValue,
  HStack,
  Text,
  VStack,
  IconButton,
  Avatar,
  Input,
  AlertIcon,
  useToast,
  Button,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { sortBy } from "lodash";
import { useParams } from "react-router";
import {
  EditableText,
  Header,
  Timeline,
  useSecureMode,
  useSecureModeModal,
} from "@sciencecorp/helix-components";
import {
  CandidateData,
  CandidateKeyringMessageData,
  UpdateCandidateSummaryParams,
  candidateKeyringMessageSchema,
  useGetCandidateQuery,
  useUpdateCandidate,
  useUpdateCandidateSummary,
} from "../../api/candidates";
import { BarChartContainer } from "./components/BarChart";
import { AddCandidate } from "./components/AddCandidate";
import {
  CandidateFeedback,
  EmptyFeedback,
  PendingCandidateFeedback,
} from "./components/CandidateFeedback";
import { candidateOverall, candidateRoundUp } from "./utils";
import type { CompletedCandidateFeedback } from "./components/CandidateFeedback";
import { useUsersQuery, userHasRole } from "../../api/user";
import { InterviewerCard } from "./components/InterviewerCard";
import { useCandidateRoleOptions } from "../../api/options";
import { Link } from "react-router-dom";
import { useCurrentUserQuery } from "../../api/user";
import {
  useCreateCandidateReference,
  useCreateCandidateTimelineEvent,
  useUpdateCandidateTimelineEvent,
} from "../../api/candidates";
import { CandidateReferenceCard } from "./components/CandidateReferenceCard";
import { CandidateInformation } from "./components/CandidateInformation";
import { NextStepsWidget } from "./components/NextStepsWidget";
import { DateTime } from "luxon";
import { orderBy } from "lodash";
import { humanize, titleize } from "inflection";
import {
  CandidateFeedbackEncryptedData,
  candidateFeedbackEncryptedDataSchema,
} from "../../api/candidate_feedback";
import { KeyringZodHelper } from "../../helpers/KeyringZodHelper";
import { useDecryptOnSecureMode, useSecureModeEffect } from "../Encryption/hooks";
import { PresentationMode } from "./components/PresentationMode";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { useDebounced } from "../hooks/useDebouncedUpdate";

const alertOptions = [
  "rejected",
  "manager_rejected",
  "ceo_rejected",
  "ceo_approved",
  "offer_accepted",
  "offer_declined",
];

export const CandidatePage = ({ presentationMode = false }) => {
  const candidateKeyringHelper = new KeyringZodHelper(candidateKeyringMessageSchema);

  const { id } = useParams();
  if (!id) return null;
  const toast = useToast();
  const [titleString, setTitleString] = useState<string>("");
  const candidate = useGetCandidateQuery(id);
  const candidateRoles = useCandidateRoleOptions();
  const userQuery = useCurrentUserQuery();
  const allUsersQuery = useUsersQuery();
  const { mutate: updateCandidate } = useUpdateCandidate();
  const { mutate: createCandidateReference } = useCreateCandidateReference();
  const { mutateAsync: createCandidateTimelineEvent, isLoading: createTimelineEventIsLoading } =
    useCreateCandidateTimelineEvent();
  const debouncedCreateCandidateTimelineEvent = useDebounced(
    createCandidateTimelineEvent,
    [createCandidateTimelineEvent],
    500
  );
  const { mutate: updateCandidateTimelineEvent } = useUpdateCandidateTimelineEvent();
  const { mutate: updateCandidateSummary, isLoading: updateSummaryIsLoading } =
    useUpdateCandidateSummary();
  const [pendingFeedback, setPendingFeedback] = useState<PendingCandidateFeedback[]>([]);
  const [finishedFeedback, setFinishedFeedback] = useState<CompletedCandidateFeedback[]>([]);
  const [comment, setComment] = useState<string>("");

  const [_smState, smDispatch] = useSecureMode();
  const feedbackKeyringHelper = new KeyringZodHelper(candidateFeedbackEncryptedDataSchema);
  const [decryptedFeedbacksById, setDecryptedFeedbacksById] =
    useState<Record<number, CandidateFeedbackEncryptedData | undefined>>();
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const keyringContent = candidate.data?.keyring_message?.content || null;
  const decryptedResult = useDecryptOnSecureMode(candidateKeyringMessageSchema, keyringContent, [
    keyringContent,
  ]);

  const handleUpdateSummary = useCallback(
    (params: UpdateCandidateSummaryParams) => {
      if (!candidate.data) return;
      updateCandidateSummary(params, {
        onSuccess: (result) => {
          updateCandidateData({ summary: result.summary });
        },
      });
    },
    [candidate]
  );

  const updatedFieldsToCandidate = (
    fields: Partial<CandidateKeyringMessageData>
  ): Partial<CandidateData> => {
    const result: Partial<CandidateData> = {};
    for (const key in fields) {
      if (key !== "id" && key !== "candidate_timeline_events") {
        result[key] = fields[key];
      }
    }
    return result;
  };

  const updateCandidateData = (
    updatedKeyringMessageFields: Partial<CandidateKeyringMessageData>
  ) => {
    if (candidate.isSuccess && (decryptedResult.isSuccess || decryptedResult.nothingToDecrypt)) {
      const updatedKeyringMessage = {
        ...decryptedResult.data,
        ...updatedKeyringMessageFields,
      };

      const newKeyringContent = candidateKeyringHelper.encrypt(
        updatedKeyringMessage,
        candidate.data.keyring_user_keys.map((ele) => ele.public_key)
      );

      const updatedCandidate = {
        id: candidate.data.id,
        ...updatedFieldsToCandidate(updatedKeyringMessageFields),
        keyring_message: {
          ...candidate.data.keyring_message,
          message_type: "candidate_data_v1",
          content: newKeyringContent,
        },
      };
      updateCandidate(updatedCandidate, {
        onSuccess: () => {
          if (updatedKeyringMessageFields.notes && userQuery.data) {
            createCandidateTimelineEvent({
              candidate_id: candidate.data.id,
              user_id: userQuery.data.id,
              notes: updatedKeyringMessageFields.notes,
            });
          }
        },
      });
    }
  };

  useSecureModeModal(
    "Enter your PIN to view candidate feedback",
    userQuery.data?.encrypted_private_key
  );

  useSecureModeEffect(
    (privateKey) => {
      if (candidate.isSuccess) {
        candidate.data.candidate_feedbacks.forEach((feedback) => {
          if (feedback.keyring_message) {
            const decryptedFeedback = feedbackKeyringHelper.decrypt(
              feedback.keyring_message.content,
              privateKey,
              smDispatch
            );
            setDecryptedFeedbacksById((prev) => ({
              ...(prev || {}),
              [feedback.id]: decryptedFeedback,
            }));
          }
        });
      }
    },
    [candidate.isSuccess]
  );

  const timelineItems = useMemo(
    () =>
      orderBy(candidate.data?.candidate_timeline_events, ["created_at"], ["asc"]).map((event) => {
        const { id, created_at, notes, user } = event;
        return {
          date: !notes.length ? (
            <Text fontSize="sm">
              Started at {DateTime.fromISO(created_at).toFormat("LLL dd, yyyy")} by{" "}
              <Box as="span" fontWeight="semibold">
                {user.name}
              </Box>
            </Text>
          ) : (
            <Text fontSize="sm">
              Comment at {DateTime.fromISO(created_at).toFormat("LLL dd, yyyy")} by{" "}
              <Box as="span" fontWeight="semibold">
                {user.name}
              </Box>
            </Text>
          ),
          body: notes && userQuery.data && (
            <EditableText
              persistentEdit={true}
              disabled={userQuery.data.id !== user.id}
              defaultValue={notes}
              onSubmit={(value) => {
                if (value) updateCandidateTimelineEvent({ id: id, notes: value });
              }}
            />
          ),
          user: <Avatar src={user.picture_uri} size="xs" />,
        };
      }),
    [candidate.isSuccess, candidate.data?.candidate_timeline_events, userQuery.isSuccess]
  );
  const editable = userHasRole(userQuery, "recruiting_admin");
  const isCeo = userHasRole(userQuery, "ceo") || false;
  const isFinanceApprover = userHasRole(userQuery, "finance_approver") || false;
  useEffect(() => {
    if (candidate.isSuccess) {
      setTitleString(
        candidate.data.name[candidate.data.name.length - 1] === "s"
          ? `${candidate.data.name}' Interview Debrief`
          : `${candidate.data.name}'s Interview Debrief`
      );
      const pending: PendingCandidateFeedback[] = [];
      const finished: CompletedCandidateFeedback[] = [];
      candidate.data.candidate_feedbacks.forEach((partialFeedback) => {
        const decryptedFeedback = (decryptedFeedbacksById || {})[partialFeedback.id];
        if (partialFeedback.keyring_message === null || decryptedFeedback === undefined) {
          pending.push(partialFeedback);
        } else {
          const feedback = {
            ...partialFeedback,
            ...decryptedFeedback,
          };
          if (feedback.overall_score !== 0) finished.push(feedback);
          else pending.push(feedback);
        }
      });
      setPendingFeedback(pending);
      setFinishedFeedback(finished);
    }
  }, [candidate.status, decryptedFeedbacksById]);

  const candidateOverallData = candidateOverall(finishedFeedback);
  const candidateDataRoundUpData = candidateRoundUp(finishedFeedback);

  const handleEditSuccess = () => {
    toast({
      title: "Done!",
      description: "You have updated the candidate's information.",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  if (presentationMode) {
    return (
      <PresentationMode
        onClose={() => window.close()}
        candidateDataRoundUpData={candidateDataRoundUpData}
        candidateOverallData={candidateOverallData}
        candidate={candidate.data}
        finishedFeedback={finishedFeedback}
        updateCandidateData={updateCandidateData}
      />
    );
  }

  return candidate.isSuccess ? (
    <Flex flexDirection="column" bg={bgColor} mx={-4} p={4} mb={-6}>
      {alertOptions.includes(candidate.data.decision) && (
        <FinalDecision decision={candidate.data.decision} />
      )}
      <Header
        title={titleString}
        actions={[
          <Button
            as="a"
            href={`/hiring/candidates/${id}/present`}
            target="_blank"
            rel="noopener noreferrer"
            colorScheme="teal"
            leftIcon={<HiOutlinePresentationChartLine />}>
            Present Results
          </Button>,
          <AddCandidate
            location={"candidate"}
            candidate={candidate.data}
            setEditSuccess={handleEditSuccess}
            candidateRoles={candidateRoles}
          />,
        ]}
      />

      <Flex direction="column" gap={4}>
        <Flex direction={["column", "column", "row"]} gap={4}>
          <CandidateFeedback
            candidate={candidate.data}
            summary={candidate.data.summary}
            section={"Summary"}
            editable={editable}
            finishedFeedback={finishedFeedback}
            isLoading={updateSummaryIsLoading}
            updateCandidate={updateCandidateData}
            onSummaryUpdatePressed={() =>
              handleUpdateSummary({
                overall_feedback: finishedFeedback.map((ele) => ele.overall_feedback).join("\n"),
                strengths: finishedFeedback.map((ele) => ele.strengths).join("\n"),
                concerns: finishedFeedback.map((ele) => ele.concerns).join("\n"),
              })
            }
            isPresentationMode={false}
          />
          <BarChartContainer
            title="Propensity to Extend Job Offer"
            question="Based on your individual experience with the candidate, how interested are you in extending an offer?"
            data={candidateOverallData}
            barLabels={[1, 2, 3, 4]}
            axisYLabel="No. of Interviewers"
            tickFormat={[0, 1, 2, 3, 4, 5, 6]}
            isPresentationMode={false}
          />
        </Flex>

        <Flex direction={["column", "column", "row"]} gap={4}>
          <CandidateFeedback
            candidate={candidate.data}
            section={"Explanations"}
            question={"What was your reason for the given score?"}
            finishedFeedback={finishedFeedback}
            updateCandidate={updateCandidateData}
            isPresentationMode={false}
          />
          <BarChartContainer
            title="Data Roundup"
            question="How would you rate the candidate across these aspects?"
            data={candidateDataRoundUpData}
            barLabels={["technical", "culture", "mission", "communication"]}
            axisYLabel="Average Scores"
            tickFormat={[0, 1, 2, 3, 4]}
            isPresentationMode={false}
          />
        </Flex>
        <Flex direction={["column", "column", "row"]} gap={4}>
          <CandidateFeedback
            candidate={candidate.data}
            section={"Strengths"}
            finishedFeedback={finishedFeedback}
            updateCandidate={updateCandidateData}
            isPresentationMode={false}
          />
          <CandidateFeedback
            candidate={candidate.data}
            section={"Opportunity for Growth/Areas of Concern"}
            finishedFeedback={finishedFeedback}
            updateCandidate={updateCandidateData}
            isPresentationMode={false}
          />
        </Flex>

        <Flex direction={["column", "column", "row"]} gap={4}>
          <Box
            width={["100%", "100%", "50%"]}
            bg={useColorModeValue("white", "gray.700")}
            borderRadius="md"
            border="1px"
            p={8}
            borderColor={useColorModeValue("gray.200", "gray.600")}>
            <HStack width="100%" justify="space-between">
              <Heading size="md">References</Heading>
              {editable && candidate.data.decision === "references" && (
                <IconButton
                  size="sm"
                  icon={<AddIcon />}
                  onClick={() => createCandidateReference({ candidate_id: candidate.data?.id })}
                  aria-label="add reference"
                />
              )}
            </HStack>
            {candidate.data.candidate_references.length ? (
              <VStack mt={6}>
                {candidate.data.candidate_references.map((reference) => (
                  <CandidateReferenceCard
                    key={reference.id}
                    reference={reference}
                    location={"debrief"}
                  />
                ))}
              </VStack>
            ) : (
              <EmptyFeedback />
            )}
          </Box>
          <Box
            width={["100%", "100%", "50%"]}
            bg={useColorModeValue("white", "gray.700")}
            borderRadius="md"
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.600")}
            p={4}>
            {candidate.data && (decryptedResult.isSuccess || decryptedResult.nothingToDecrypt) ? (
              <CandidateInformation
                candidate={candidate.data}
                candidateKrData={decryptedResult.data || {}}
                updateCandidateData={updateCandidateData}
              />
            ) : (
              <> </>
            )}
          </Box>
        </Flex>
        <Flex direction={["column", "column", "row"]} gap={4}>
          <Box
            bg={useColorModeValue("white", "gray.700")}
            width={["100%", "100%", "50%"]}
            borderRadius="md"
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.600")}
            p={8}>
            <Heading size="md">Timeline</Heading>
            <Box maxWidth="xl" mt={2}>
              <Timeline items={timelineItems as any} />
              <HStack mt={8}>
                <Avatar size="sm" src={userQuery.data?.picture_uri} />
                <Input
                  borderRadius="md"
                  placeholder="Add a comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      userQuery.data &&
                      comment.length &&
                      !createTimelineEventIsLoading
                    ) {
                      debouncedCreateCandidateTimelineEvent({
                        candidate_id: candidate.data?.id,
                        user_id: userQuery.data.id,
                        notes: comment,
                      });
                      setComment("");
                    }
                  }}
                />
              </HStack>
            </Box>
          </Box>
          <Box
            bg={useColorModeValue("white", "gray.700")}
            borderRadius="md"
            width={["100%", "100%", "50%"]}
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.600")}
            p={8}>
            <Flex flexDirection="column" justify="space-between" minHeight={400} height="100%">
              <Box>
                <Heading size="md" mb={4}>
                  Submitted
                </Heading>
                <Box
                  display="flex"
                  flexDirection={["column", "row", "row"]}
                  flexWrap="wrap"
                  gap={4}>
                  {allUsersQuery.isSuccess &&
                    sortBy(finishedFeedback, ["user.name"]).map((feedback) => (
                      <Link to={`/hiring/feedback/${feedback.id}`} key={feedback.id}>
                        <InterviewerCard key={feedback.id} id={feedback.user_id} />
                      </Link>
                    ))}
                </Box>
              </Box>
              <Box>
                <Heading size="md" mb={4}>
                  Pending Submission
                </Heading>
                <Box
                  display="flex"
                  flexDirection={["column", "row", "row"]}
                  flexWrap="wrap"
                  gap={4}
                  minHeight={20}>
                  {pendingFeedback.length
                    ? pendingFeedback.map((feedback) => (
                        <InterviewerCard key={feedback.id} id={feedback.user_id} />
                      ))
                    : null}
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <NextStepsWidget
        candidate={candidate.data}
        isCeo={isCeo}
        isFinanceApprover={isFinanceApprover}
        overallFeedback={finishedFeedback.map((ele) => ele.overall_feedback)}
        strengths={finishedFeedback.map((ele) => ele.strengths)}
        concerns={finishedFeedback.map((ele) => ele.concerns)}
        updateCandidateSummary={handleUpdateSummary}
      />
    </Flex>
  ) : null;
};

const FinalDecision = ({ decision }) => {
  switch (decision) {
    case "ceo_approved":
      return (
        <Flex justify="center" mx={-4} my={2}>
          <Alert status="success" variant="subtle" alignItems="center">
            <AlertIcon />
            <VStack width="100%" align="start" spacing={0}>
              <AlertTitle>Approved</AlertTitle>
              <AlertDescription>
                Hiring Approval for this candidate has been granted.
              </AlertDescription>
            </VStack>
          </Alert>
        </Flex>
      );
    case "offer_accepted":
      return (
        <Flex justify="center" mx={-4} my={2}>
          <Alert status="success" variant="subtle" alignItems="center">
            <AlertIcon />
            <VStack width="100%" align="start" spacing={0}>
              <AlertTitle>Accepted</AlertTitle>
              <AlertDescription>Candidate has accepted the offer.</AlertDescription>
            </VStack>
          </Alert>
        </Flex>
      );
    case "offer_declined":
      return (
        <Flex justify="center" mx={-4} my={2}>
          <Alert status="error" variant="subtle" alignItems="center">
            <AlertIcon />
            <VStack width="100%" align="start" spacing={0}>
              <AlertTitle>Offer Declined</AlertTitle>
              <AlertDescription>Candidate has declined the offer.</AlertDescription>
            </VStack>
          </Alert>
        </Flex>
      );
    default:
      return (
        <Flex justify="center" mx={-4} my={2}>
          <Alert status="error" variant="subtle" alignItems="center">
            <AlertIcon />
            <VStack width="100%" align="start" spacing={0}>
              <AlertTitle>{titleize(humanize(decision))}</AlertTitle>
              <AlertDescription>Candidate was rejected.</AlertDescription>
            </VStack>
          </Alert>
        </Flex>
      );
  }
};
