import { Badge, Box, Flex, HStack, Heading } from "@chakra-ui/react";
import { Facets, Keyable, buildFacets, useCollection } from "@sciencecorp/helix-components";
import React, { useCallback, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Filters, searchParams } from "../../../../../api/collection_types";
import { CurrencySelect } from "../../../../CurrencySelect";
import { ContractTab } from "./tabs/ContractTab";
import { PaymentsTab } from "./tabs/PaymentsTab";
import { PurchaseTab } from "./tabs/PurchaseTab";
import { ReimbursementsTab } from "./tabs/ReimbursementsTab";
import { ServiceRequestTab } from "./tabs/ServiceRequestTab";
import { TabbedTable, TabbedTableTab, TabbedTableTabProps } from "../../../../TabbedTable";
import { useTableState } from "../../../../useTableReducer";
import { BackgroundReportButton } from "../../../../shared/BackgroundReportButton";

export const TransactionTable = ({
  queryFilters,
  canDownload,
}: {
  queryFilters: Filters;
  canDownload?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionTabIndex, setTransactionTabIndex] = useState(0);
  const [tableState, setTableState] = useTableState({
    aggregations: {},
    pagination: { per_page: 10, page: 1, n_pages: 1, total: 0 },
    filters: {},
  });

  const PAYMENTS_TAB = 0;
  const PURCHASE_TAB = 1;
  const CONTRACT_TAB = 2;
  const SERVICE_REQUEST_TAB = 3;
  const REIMBURSEMENT_TAB = 4;

  const handleTabChange = useCallback((index: number) => {
    setTransactionTabIndex && setTransactionTabIndex(index);
    resetFacets();
    resetPagination();
    navigate(location.pathname);
  }, []);

  const {
    onPagination,
    onOrder,
    order,
    pagination,
    filters,
    facets,
    onFacets,
    resetFacets,
    resetPagination,
  } = useCollection({ pagination: { per_page: 10 } });

  const [currency, setCurrency] = useState<string | null>(null);

  const defaultTabArgs: TabbedTableTabProps = {
    currency: currency || "USD",
    term: "*",
    aggs: undefined,
    filters: {
      ...filters,
      ...queryFilters,
    },
    pagination,
    order,
    onPagination,
    onOrder,
    tableDispatch: setTableState,
    active: false,
  };

  const expenditureTabs: TabbedTableTab[] = [
    {
      id: PAYMENTS_TAB,
      label: PaymentsTab.label,
      component: PaymentsTab.component,
      overrides: { aggs: ["vendor_name", "status", "type", "by_month"] },
    },
    {
      id: PURCHASE_TAB,
      label: PurchaseTab.label,
      component: PurchaseTab.component,
      overrides: {
        aggs: ["vendor_name", "status"],
        filters: {
          ...filters,
          ...queryFilters,
          status: ["awaiting_purchase", "awaiting_payment", "awaiting_delivery", "delivered"],
        },
      },
    },
    {
      id: CONTRACT_TAB,
      label: ContractTab.label,
      component: ContractTab.component,
      overrides: {
        aggs: ["source_of_authority", "vendor_name", "billing_period"],
        filters: {
          ...filters,
          ...queryFilters,
          archived_at: null,
        },
      },
    },
    {
      id: SERVICE_REQUEST_TAB,
      label: ServiceRequestTab.label,
      component: ServiceRequestTab.component,
      overrides: {
        aggs: ["fulfilling_team_name", "requesting_team_name"],
        filters: {
          ...filters,
          ...queryFilters,
          has_cost: true,
        },
      },
    },
    {
      id: REIMBURSEMENT_TAB,
      label: ReimbursementsTab.label,
      component: ReimbursementsTab.component,
      overrides: {
        aggs: ["approval_status", "month_paid_at"],
        filters: {
          ...filters,
          ...queryFilters,
          approval_status: ["approved", "completed"],
        },
      },
    },
  ];
  const populatedFacets = buildFacets(tableState.aggregations || {}, facets);

  return (
    <Flex
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      p={6}
      direction="column"
      gap={6}>
      <Flex
        direction={["column", "row"]}
        gap={["2", "auto"]}
        justifyContent="space-between"
        alignItems={["flex-start", "center"]}
        w="100%">
        <Flex gap={2}>
          <Heading size="md">Transactions</Heading>
          <Box>
            <Badge>{tableState.pagination.total}</Badge>
          </Box>
        </Flex>
        <HStack>
          <CurrencySelect
            value={currency}
            onChange={(value) => {
              if (value === "original") {
                setCurrency(null);
              } else {
                setCurrency(value);
              }
            }}
            includeOriginal
          />
          <Facets
            facets={populatedFacets}
            defaultIndex={[0]}
            onChange={onFacets}
            variant="button"
          />
          {canDownload && (
            <BackgroundReportButton
              jobName="Reports::Payments"
              args={[
                searchParams({
                  term: "*",
                  filters: { ...filters, ...queryFilters },
                }),
                currency || "USD",
              ]}
              title="budget-group-payments"
              buttonName="Export"
            />
          )}
        </HStack>
      </Flex>
      <TabbedTable
        tabs={expenditureTabs}
        handleTabChange={handleTabChange}
        defaultTabProps={defaultTabArgs}
        activeTab={transactionTabIndex}
      />
    </Flex>
  );
};
