import React, { useState } from "react";
import {
  useToast,
  Text,
  FormControl,
  FormLabel,
  Button,
  useDisclosure,
  Card,
  CardBody,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import { AddIcon } from "@chakra-ui/icons";
import { UnassociatedBudgetSelect } from "../../../../Purchasing/UnassociatedBudgetSelect";
import { useBulkMoveBudgets } from "../../../../../api/budget";
import { BudgetGroupShowData } from "../../../../../api/budget_groups";
import { MoneyText } from "../../../../MoneyText";

export const AddBudgetModal = ({ budgetGroup }: { budgetGroup: BudgetGroupShowData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [budgetId, setBudgetId] = useState<number | null>(null);
  const { mutate: bulkUpdate, isLoading } = useBulkMoveBudgets();
  const toast = useToast();

  const handleClose = () => {
    onClose();
    setBudgetId(null);
  };

  const handleSubmit = () => {
    if (budgetId)
      bulkUpdate(
        { budgetIds: [budgetId], budgetGroupId: budgetGroup.id },
        {
          onSuccess: () => {
            handleClose();
            toast({
              title: "Succcess",
              description: `You added a budget to ${budgetGroup.name}`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          },
          onError: () => {
            toast({
              title: "Error",
              description: `Failed to add budget`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} onClick={onOpen} isDisabled={!!budgetGroup.archived_at}>
        Add Budget
      </Button>

      <FormModal
        submitButtonColorSchema="teal"
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={handleClose}
        isLoading={isLoading}
        title="Add a Budget"
        handleSubmit={handleSubmit}>
        <Card w="100%">
          <CardBody display="flex" flexDir="column" alignItems="center">
            <Text color={useColorModeValue("gray.500", "auto")}>
              {budgetGroup.name}'s Available Balance
            </Text>
            <MoneyText
              fontWeight="bold"
              fontSize="2xl"
              money={budgetGroup.summary.remaining_amount}
              formatOptions={{ compact: "never" }}
            />
          </CardBody>
        </Card>
        <FormControl>
          <FormLabel>Choose Budget to Add</FormLabel>
          <UnassociatedBudgetSelect
            onChange={(budgetId) => setBudgetId(budgetId)}
            budgetId={budgetId}
            emptyOptionText="Add Budget"
          />
        </FormControl>
      </FormModal>
    </>
  );
};
