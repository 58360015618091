import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  HStack,
  useColorModeValue,
  useToast,
  Tag,
  Card,
  CardBody,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { BudgetIndexItemData, useBulkMoveBudgets } from "../../../../api/budget";
import { MoneyText } from "../../../MoneyText";
import { useBudgetGroupOptions } from "../../../../api/options";
import { useGetBudgetGroup } from "../../../../api/budget_groups";

type FormValuesAddUnassignedBudgetToBudgetGroup = {
  destination_id: number;
};

type AddUnassignedBudgetToBudgetGroupModalProps = {
  isOpen: boolean;
  onClose: () => void;
  groups: BudgetIndexItemData[];
  setSelectedBudgets: React.Dispatch<React.SetStateAction<BudgetIndexItemData[]>>;
};

export const AddUnassignedBudgetToBudgetGroupModal = ({
  isOpen,
  onClose,
  groups,
  setSelectedBudgets,
}: AddUnassignedBudgetToBudgetGroupModalProps) => {
  const { setValue, control, watch, reset } = useForm<FormValuesAddUnassignedBudgetToBudgetGroup>();
  const formValues = watch();
  const toast = useToast();

  const removeBudget = (budgetId: number) => {
    setSelectedBudgets((budgets) => budgets.filter((budget) => budget.id !== budgetId));
  };
  const borderGray = useColorModeValue("gray.50", "gray.500");
  const backgroundColorGray = useColorModeValue("gray.50", "gray.700");
  const textGray = useColorModeValue("gray.500", "auto");
  const budgetGroupOptions = useBudgetGroupOptions();

  const { data: budgetGroup, isLoading } = useGetBudgetGroup(formValues.destination_id);

  const disableSubmitButton = !formValues.destination_id;
  const { mutateAsync: bulkMoveBudgets, isLoading: bulkMoveBudgetsIsLoading } =
    useBulkMoveBudgets();

  const handleSubmit = (modalData: FormValuesAddUnassignedBudgetToBudgetGroup) => {
    bulkMoveBudgets({
      budgetIds: groups.map((budget) => budget.id),
      budgetGroupId: modalData.destination_id,
    })
      .then(() => {
        toast({
          title: "Budgets added to Budget Group",
          status: "success",
          duration: 5000,
        });
        setSelectedBudgets([]);
        handleClose();
      })
      .catch((error) => {
        toast({
          title: "Failed to add budgets to Budget Group",
          status: "error",
          duration: 5000,
        });
        console.error(error);
      });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <FormModal
      title="Add to Budget Group"
      submitButtonColorSchema="teal"
      size="xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={bulkMoveBudgetsIsLoading}
      submitButtonDisabled={disableSubmitButton}
      handleSubmit={() => handleSubmit(formValues)}>
      <Controller
        name="destination_id"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Choose Destination Budget Group</FormLabel>
            <Select {...field} options={budgetGroupOptions} />
          </FormControl>
        )}
      />

      <Card w={"100%"} align="center">
        <CardBody textAlign="center">
          <Text color={textGray}>Available Balance</Text>
          {formValues.destination_id ? (
            <MoneyText
              as="span"
              fontSize="2xl"
              fontWeight="semibold"
              money={budgetGroup?.summary.remaining_amount}
              formatOptions={{ compact: "never", hideCents: true }}
            />
          ) : (
            <Text fontSize={"bold"}>-</Text>
          )}
        </CardBody>
      </Card>
      <Flex
        direction={"column"}
        width={"100%"}
        p={"2"}
        border={"1px"}
        borderRadius={"md"}
        borderColor={borderGray}
        bgColor={backgroundColorGray}>
        <HStack
          margin={"auto"}
          p={"2"}
          borderBottom="1px"
          w="100%"
          justify="center"
          borderColor="chakra-border-color">
          <Text fontSize={"sm"}>Selected Budgets</Text>
          <Tag>{groups.length}</Tag>
        </HStack>

        <FormControl>
          {groups.map((group) => (
            <HStack justifyContent={"space-between"} padding={"2"}>
              <Text>{group.budgetable.name}</Text>
              <SmallCloseIcon
                onClick={() => removeBudget(group.id)}
                cursor={"pointer"}
                color={"gray.500"}
              />
            </HStack>
          ))}
        </FormControl>
      </Flex>
    </FormModal>
  );
};
