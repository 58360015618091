import React, { useState } from "react";
import { FormModal } from "@sciencecorp/helix-components";
import {
  Flex,
  Input,
  FormControl,
  FormLabel,
  Button,
  useDisclosure,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useCreateArchetype } from "../../../api/archetype";
import { AddIcon } from "@chakra-ui/icons";

export const NewArchetypeModal = () => {
  const { mutate: createArchetype } = useCreateArchetype();
  const [name, setName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showError, setShowError] = useState(false);

  const handleClose = () => {
    setName("");
    onClose();
    setShowError(false);
  };
  const handleSubmit = () => {
    createArchetype(
      { name },
      {
        onSuccess: () => handleClose(),
        onError: () => {
          setShowError(true);
        },
      }
    );
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
        Add Role
      </Button>

      <FormModal
        title="Add Role"
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        submitButtonColorSchema="teal"
        submitButtonTitle="Done">
        <FormControl isInvalid={showError}>
          <FormLabel>Role Name</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
          <FormErrorMessage>Archetype already exists</FormErrorMessage>
        </FormControl>
      </FormModal>
    </>
  );
};
