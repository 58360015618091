import React, { useState } from "react";
import {
  Heading,
  Box,
  SimpleGrid,
  useDisclosure,
  IconButton,
  Avatar,
  Flex,
  HStack,
  LinkBox,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AttributesTable, EditableText, EditableSelect } from "@sciencecorp/helix-components";
import { titleize, humanize } from "inflection";
import {
  invalidateInventories,
  InventoryShowData,
  useDeleteInventoryUser,
  useGetInventoryEvents,
  useUpdateInventory,
} from "../../../../api/inventory";
import { InventoryCategoryOptions } from "../../util";
import { EditableInventoryAssociation } from "./EditableInventoryAssociation";
import { StockLocations } from "./StockLocations";
import { Attachments } from "../../../CapitalEquipments/components/DetailsPage/Attachments";
import { CapitalEquipmentNotes } from "../../../CapitalEquipments/components/DetailsPage/CapitalEquipmentNotes";
import { CapitalEquipmentVendorInformation } from "../../../CapitalEquipments/components/DetailsPage/CapitalEquipmentVendorInformation";
import { TimelineTable } from "../../../shared/TimelineTable";
import { EditIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";

type InventoryDetailInformationProps = {
  inventory: InventoryShowData;
  isInventoryManager: boolean;
};

export const InventoryDetailInformation = ({
  inventory,
  isInventoryManager,
}: InventoryDetailInformationProps) => {
  const { mutate: updateInventory, isLoading: isUpdateLoading } = useUpdateInventory();
  const { data: events } = useGetInventoryEvents(inventory.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: deleteInventoryUser, isLoading } = useDeleteInventoryUser(inventory.id);

  return (
    <>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} w="100%">
        {/* Notes */}
        <CapitalEquipmentNotes
          notes={inventory.description || ""}
          updateNotes={(value: string) => updateInventory({ description: value, id: inventory.id })}
          isLoading={isUpdateLoading}
        />

        {/* Attachments */}
        <Attachments data={inventory} isManager={isInventoryManager} type="inventory" />

        {/* Basic Information */}
        <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
          <Heading size="md" mb={4} fontWeight="medium">
            Basic Information
          </Heading>
          <AttributesTable
            attributes={[
              {
                label: "ID",
                value: (
                  <EditableText
                    defaultValue={inventory.sku || ""}
                    preview={inventory.sku || ""}
                    onSubmit={(value) => {
                      updateInventory({ id: inventory.id, sku: String(value) });
                    }}
                  />
                ),
              },
              {
                label: "Type",
                value: (
                  <Box maxW={40}>
                    <EditableSelect
                      options={[
                        { label: "Consumable", value: "consumable" },
                        { label: "Durable", value: "durable" },
                      ]}
                      selectedValue={inventory.is_consumable ? "consumable" : "durable"}
                      onSubmit={(value) => {
                        updateInventory({
                          id: inventory.id,
                          is_consumable: value === "consumable",
                        });
                      }}
                    />
                  </Box>
                ),
              },
              {
                label: "Category",
                value: (
                  <EditableSelect
                    selectedValue={inventory.category || ""}
                    preview={titleize(humanize(inventory.category)) || ""}
                    options={InventoryCategoryOptions}
                    onSubmit={(value) => {
                      updateInventory({ id: inventory.id, category: String(value) });
                    }}
                  />
                ),
              },
              {
                label: "Owners",
                value: (
                  <HStack>
                    <Box>
                      <PreviewUserSelect
                        addedUsers={inventory.users}
                        isLoading={isLoading}
                        handleDelete={deleteInventoryUser}
                        isDisabled={!isInventoryManager}
                        additionalUsersTitle="Owners"
                      />
                    </Box>
                    <IconButton
                      icon={<EditIcon />}
                      onClick={onOpen}
                      size="xs"
                      aria-label="Add Reminder Recipient"
                    />
                  </HStack>
                ),
              },
            ]}
          />
        </Box>
        <EditableInventoryAssociation
          inventoryId={inventory.id}
          inventoryUsers={inventory?.users?.map(({ user }) => user)}
          isOpen={isOpen}
          onClose={onClose}
        />
        {/* Supplier Information */}
        <CapitalEquipmentVendorInformation
          data={inventory}
          isManager={isInventoryManager}
          type={"Inventory"}
          isDisabled={false}
        />

        {/* Stock Locations */}
        <StockLocations inventory={inventory} />

        {/* Manufacturer Information */}
        <Box border="1px" borderRadius="md" borderColor="chakra-border-color" p={6}>
          <Heading size="md" mb={4} fontWeight="medium">
            Manufacturer Information
          </Heading>
          <AttributesTable
            attributes={[
              {
                label: "Original Equipment Manufacturer",
                value: (
                  <EditableText
                    defaultValue={inventory.original_equipment_manufacturer || ""}
                    preview={inventory.original_equipment_manufacturer || ""}
                    onSubmit={(value) => {
                      updateInventory({
                        id: inventory.id,
                        original_equipment_manufacturer: String(value),
                      });
                    }}
                  />
                ),
              },
              {
                label: "Manufacturing Part Number",
                value: (
                  <EditableText
                    defaultValue={inventory.manufacturing_part_number || ""}
                    preview={inventory.manufacturing_part_number || ""}
                    onSubmit={(value) => {
                      updateInventory({
                        id: inventory.id,
                        manufacturing_part_number: String(value),
                      });
                    }}
                  />
                ),
              },
            ]}
          />
        </Box>
      </SimpleGrid>
      <TimelineTable
        events={events || []}
        timelineable_id={inventory.id}
        timelineable_type="Inventory"
        onComment={invalidateInventories(inventory.id, "events")}
        disableCommentBox={false}
      />
    </>
  );
};
