import React from "react";
import { createRoot } from "react-dom/client";

import Main from "../components/Main";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9c1f8f09793b269dfb4f3aa0ebe65d1f@o4508060151775232.ingest.us.sentry.io/4508214081093632",
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    Sentry.zodErrorsIntegration(),
  ],
  enabled: import.meta.env.MODE === "production",
  // Tracing
  tracesSampleRate: 0.02, // performance analytics. https://science-corporation.sentry.io/performance/
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://science.xyz", "https//helix.science.xyz"],
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0, // https://science-corporation.sentry.io/replays/
  // Profiling, This is a subset of the tracesSampleRate, so tracesSampleRate * profilesSampleRate = final sample rate for profiling
  profilesSampleRate: 0.5, // https://science-corporation.sentry.io/profiling/
});

const form = document.querySelector("div#app-root-gating form");
if (form) {
  (form as HTMLFormElement).submit();
} else {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<Main />);
}
