import React from "react";
import {
  Flex,
  Text,
  Box,
  HStack,
  Icon,
  Spacer,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { EmptyState } from "@sciencecorp/helix-components";
import { useGetPurchaseLineItems, useGetPurchaseReturns } from "../../../../../api/purchase";
import { BsBoxSeam } from "react-icons/bs";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { updateLineItemsQuantity } from "../../../../Purchasing/Purchase/util";
import { RestockFormValues } from "./util";

type PurchaseLineItemsSourceProps = {
  setValue: UseFormSetValue<RestockFormValues>;
  control: Control<RestockFormValues, any>;
  purchaseId: number;
};

export const PurchaseLineItemsSource = ({
  setValue,
  control,
  purchaseId,
}: PurchaseLineItemsSourceProps) => {
  const { data: purchaseLineItemsData, isSuccess: purchaseLineItemsIsSuccess } =
    useGetPurchaseLineItems(purchaseId);

  const { data: purchaseReturns } = useGetPurchaseReturns(purchaseId);

  const lineItems =
    purchaseLineItemsData
      ?.filter(
        (item) =>
          item.item_name !== "Tax" && item.item_name !== "Shipping Cost" && item.item_name !== ""
      )
      .map((item) => ({ ...item, purchase_id: purchaseId, quantity: item.quantity }))
      .filter((item) => !item.has_inventory_items) || [];

  const purchaseReturnsWithServiceRequest = purchaseReturns?.filter(
    (ret) => ret.service_request_id
  );

  const lineItemsAttachedToReturn = purchaseReturnsWithServiceRequest
    ?.map((ret) => ret.purchase_return_line_items)
    .flat();

  const purchaseLineItems =
    lineItemsAttachedToReturn &&
    lineItems &&
    updateLineItemsQuantity(lineItems, lineItemsAttachedToReturn);

  return (
    <Flex direction="column" width="100%" gap={3} p={4}>
      {purchaseLineItemsIsSuccess && purchaseLineItems && (
        <Controller
          name="purchase_line_item"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Select Line Item from Purchase #{purchaseId}</FormLabel>
              <Flex direction="column" gap={4}>
                {purchaseLineItems.length > 0 ? (
                  purchaseLineItems.map((lineItem) => (
                    <HStack
                      data-testid={`purchase-line-item-${lineItem.item_name}`}
                      key={`line-item-${lineItem.id}`}
                      _hover={{ cursor: "pointer" }}
                      p={3}
                      bg={
                        field.value?.id === lineItem.id
                          ? useColorModeValue("teal.50", "teal.500")
                          : "chakra-bg-color"
                      }
                      spacing={6}
                      border="1px"
                      borderRadius="md"
                      borderColor={
                        field.value?.id === lineItem.id
                          ? useColorModeValue("teal.200", "teal.400")
                          : "chakra-border-color"
                      }
                      onClick={() => {
                        field.onChange(lineItem);
                        setValue("quantity", lineItem.quantity);
                        setValue("amount", lineItem.unit_amount);
                        setValue(
                          "total_estimated_cost",
                          lineItem.unit_amount.times(lineItem.quantity)
                        );
                      }}>
                      <Icon as={BsBoxSeam} />
                      <Text fontSize="sm" fontWeight="medium">
                        {lineItem.item_name} ({lineItem.quantity})
                      </Text>
                      <Spacer />
                      {field.value?.id === lineItem.id ? (
                        <CheckCircleIcon
                          color={useColorModeValue("teal.500", "teal.200")}
                          boxSize={7}
                        />
                      ) : (
                        <Box
                          border="2px"
                          borderRadius="full"
                          borderColor="chakra-border-color"
                          p={3}
                        />
                      )}
                    </HStack>
                  ))
                ) : (
                  <EmptyState size="2xs" title="No purchase line items available to select" />
                )}
              </Flex>
            </FormControl>
          )}
        />
      )}
    </Flex>
  );
};
