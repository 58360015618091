import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  VStack,
  Text,
  Stack,
  MenuList,
  Menu,
  MenuItem,
  MenuButton,
  IconButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { sortBy } from "lodash";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useState, useEffect } from "react";
import { useUsersQuery } from "../../../api/user";
import {
  useCreateCandidateFeedback,
  useDeleteCandidateFeedback,
} from "../../../api/candidate_feedback";
import {
  useCreateCandidate,
  useUpdateCandidate,
  useDeleteCandidate,
} from "../../../api/candidates";
import { useUserOptions } from "../../../api/options";
import type { CandidateData } from "../../../api/candidates";
import { InterviewerCard } from "./InterviewerCard";
import { DeleteModal } from "./DeleteModal";
import { useGetBudgetHeadcountVacancy, useSearchVacancies } from "../../../api/budget_headcounts";
import { titleize } from "inflection";
import { useSearchBudgetHeadcountPanelist } from "../../../api/budget_headcount_panelist";
import { BsThreeDotsVertical } from "react-icons/bs";

type AddCandidateProps = {
  location: string;
  candidate?: CandidateData;
  setEditSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  candidateRoles: { label: string; value: string }[];
};

export const AddCandidate: React.FC<AddCandidateProps> = ({
  location,
  candidate,
  setEditSuccess,
}) => {
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [isOpen, setIsOpen] = useState(false);
  const allUsersOptions = useUserOptions();
  const { data: allUsers } = useUsersQuery();

  const { mutate: newCandidate } = useCreateCandidate();
  const { mutate: deleteCandidate } = useDeleteCandidate();
  const { mutate: updateCandidate } = useUpdateCandidate();

  const { mutate: newCandidateFeedback } = useCreateCandidateFeedback();
  const { mutate: deleteCandidateFeedback } = useDeleteCandidateFeedback();

  const [candidateName, setCandidateName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [hiringManager, setHiringManager] = useState<string | null>(null);
  const [budgetHeadcount, setBudgetHeadcount] = useState<string | null>(null);
  const [interviewerList, setInterviewerList] = useState<number[]>([]);
  const [users, setUsers] = useState<{ label: string; value: string }[]>([]);
  const navigate = useNavigate();
  const { data: panelists } = useSearchBudgetHeadcountPanelist({
    term: "*",
    filters: { budget_headcount_id: budgetHeadcount, hiring_role: "interviewer" },
  });

  const { data: budgetHeadcountData } = useGetBudgetHeadcountVacancy(Number(budgetHeadcount));

  useEffect(() => {
    // automatically set interviewer list based off vacancy
    if (panelists && allUsers && budgetHeadcount && location === "hiring") {
      setHiringManager((budgetHeadcountData?.hiring_manager?.id || "").toString());
      const interviewers = panelists.results.map((ele) => ele.user.id);
      const prevOptions = allUsersOptions.filter(
        (ele) => !interviewers.some((e) => e === Number(ele.value))
      );
      setUsers(sortBy(prevOptions, ["label"]));
      setInterviewerList(interviewers);
    }
  }, [panelists, allUsers, budgetHeadcount]);

  useEffect(() => {
    if (location === "candidate" && candidate) {
      setCandidateName(candidate.name);
      setBudgetHeadcount(
        candidate.budget_headcount ? candidate.budget_headcount.id.toString() : null
      );
      setEmail(candidate.email);
      candidate.hiring_manager_id && setHiringManager(candidate.hiring_manager_id.toString());
      const interviewers = candidate.candidate_feedbacks.map((ele) => ele.user_id);
      const prevOptions = allUsersOptions.filter(
        (user) => !interviewers.includes(Number(user.value))
      );

      setUsers(sortBy(prevOptions, ["label"]));

      setInterviewerList(interviewers);
    }
  }, [location, allUsers]);

  const closeAddModal = () => {
    if (location === "hiring") {
      setCandidateName("");
      setEmail("");
      setBudgetHeadcount(null);
      setInterviewerList([]);
      setHiringManager(null);
      setUsers(sortBy(allUsersOptions, ["label"]));
    } else if (location === "candidate" && candidate) {
      const interviewers = candidate.candidate_feedbacks.map((ele) => ele.user_id);
      const prevOptions = allUsersOptions.filter(
        (ele) => !interviewers.some((e) => e === Number(ele.value))
      );
      setUsers(sortBy(prevOptions, ["label"]));
      setInterviewerList(interviewers);
      setBudgetHeadcount(
        candidate.budget_headcount ? candidate.budget_headcount.id.toString() : null
      );
    }
    onAddClose();
  };

  const { data } = useSearchVacancies({
    term: "*",
    pagination: { per_page: -1 },
    filters: { vacant: "true", budgetable_type: "Team" },
  });

  const onInterviewerChange = (person: string) => {
    setUsers((prev) => prev.filter((ele) => ele.value !== person.toString()));
    setInterviewerList((prev) => [...prev, Number(person)]);
  };

  const handleDelete = (id: number, name: string) => {
    setUsers((prev) => sortBy([...prev, { label: name, value: id.toString() }], "label"));
    setInterviewerList((prev) => prev.filter((ele) => ele !== id));
  };

  const onDeleteCandidate = () => {
    if (deleteCandidate && candidate) {
      deleteCandidate(candidate.id, {
        onSuccess: () => {
          navigate("/hiring", { state: { alert: "You have deleted a candidate" } });
          onDeleteClose();
        },
      });
    }
  };
  const onSubmit = () => {
    if (budgetHeadcount) {
      if (location === "hiring") {
        newCandidate(
          {
            name: candidateName as string,
            budget_headcount_id: Number(budgetHeadcount),
            email: email as string,
            hiring_manager_id: Number(hiringManager),
          },
          {
            onSuccess: (data) => {
              interviewerList.forEach((person) => {
                newCandidateFeedback({
                  candidate_id: data.id,
                  user_id: person,
                });
              });
              setInterviewerList([]);
              setUsers(sortBy(allUsersOptions, "label"));
              closeAddModal();
            },
          }
        );
      } else {
        const currentInterviewers =
          candidate?.candidate_feedbacks.map((ele) => ({ id: ele.id, user_id: ele.user_id })) || [];

        const newInterviewers = interviewerList.filter(
          (member) =>
            !currentInterviewers.some((interviewer) => interviewer.user_id === Number(member))
        );

        const deletedInterviewers = currentInterviewers.filter(
          (feedback) => !interviewerList.includes(feedback.user_id)
        );
        if (candidate) {
          updateCandidate(
            {
              id: candidate.id,
              name: candidateName as string,
              budget_headcount_id: Number(budgetHeadcount),
              email: email as string,
              hiring_manager_id: Number(hiringManager),
            },
            {
              onSuccess: () => {
                if (setEditSuccess) setEditSuccess(true);
                onAddClose();
              },
            }
          );
          if (newInterviewers.length) {
            newInterviewers.forEach((person) => {
              newCandidateFeedback({
                candidate_id: candidate.id,
                user_id: Number(person),
              });
            });
          }
          if (deletedInterviewers.length) {
            deletedInterviewers.forEach((feedback) => {
              deleteCandidateFeedback(feedback.id);
            });
          }
        }
      }
    }
  };

  const submitButton = location === "hiring" ? "Submit" : "Done";

  return (
    <Flex>
      {location === "hiring" ? (
        <Button colorScheme="teal" leftIcon={<AddIcon />} onClick={onAddOpen}>
          Add Candidate
        </Button>
      ) : (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<BsThreeDotsVertical />}
            size="md"
            aria-label="More actions"
            isActive={isOpen}
          />
          <MenuList>
            <MenuItem icon={<EditIcon />} onClick={onAddOpen}>
              Edit Candidate Information
            </MenuItem>
            <MenuItem icon={<DeleteIcon />} onClick={onDeleteOpen}>
              Delete Candidate
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      <FormModal
        title={`${location === "hiring" ? "Add" : "Edit"} Candidate`}
        closeOnOverlayClick={false}
        size="3xl"
        isOpen={isAddOpen}
        onClose={closeAddModal}
        submitButtonTitle={submitButton}
        handleSubmit={onSubmit}
        submitButtonDisabled={!candidateName || !email || !budgetHeadcount || !hiringManager}
        submitButtonColorSchema="teal">
        <VStack width="100%" spacing={4}>
          <Stack
            direction={{ base: "column", md: "row" }}
            justify={"space-around"}
            spacing={4}
            width="100%">
            <FormControl isRequired>
              <FormLabel> Name </FormLabel>
              <Input
                placeholder="Type Candidate Name"
                value={candidateName}
                onChange={(e) => setCandidateName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel> Email </FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Type Candidate Email"
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: "column", md: "row" }} spacing={4} width="100%">
            <FormControl isRequired>
              <FormLabel> Vacancy </FormLabel>
              <Select
                variant="outline"
                options={
                  data?.results.map((ele) => ({
                    label: `${ele.budgetable.name} - ${ele.archetype.name} (${titleize(
                      ele.priority
                    )})`,
                    value: ele.id.toString(),
                  })) || []
                }
                placeholder="Select Vacancy"
                value={budgetHeadcount}
                onChange={(value) => {
                  setBudgetHeadcount(value.toString());
                }}
              />
            </FormControl>
          </Stack>
          <FormControl isRequired>
            <FormLabel> Hiring Manager </FormLabel>
            <Select
              variant="outline"
              options={allUsersOptions}
              placeholder="Add Hiring Manager"
              value={hiringManager}
              onChange={(value) => {
                setHiringManager(value.toString());
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel> Interviewers </FormLabel>
            <Select
              variant="outline"
              options={users}
              placeholder="Add Interviewer"
              value={null}
              onChange={(options) => {
                if (options) onInterviewerChange(options.toString());
              }}
            />
          </FormControl>

          <VStack width="100%" align="start" mt={0}>
            <Flex direction="row" justify="start" gap={5} wrap="wrap">
              {interviewerList.length === 0 ? (
                <Text color="gray.500" fontSize="xs">
                  Select interviewers to preview here
                </Text>
              ) : (
                interviewerList.map((person) => (
                  <InterviewerCard key={person} id={person} handleDelete={handleDelete} />
                ))
              )}
            </Flex>
          </VStack>
        </VStack>
      </FormModal>
      <DeleteModal
        title="Delete Candidate Information?"
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={onDeleteCandidate}
      />
    </Flex>
  );
};
