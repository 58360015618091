import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverContent,
  Button,
  Input,
  useColorModeValue,
  useDisclosure,
  PopoverAnchor,
  VStack,
  Text,
  Icon,
  HStack,
  MenuItem,
  Menu,
} from "@chakra-ui/react";
import { useInventoryOptions } from "../../../../api/options";
import { BsBoxSeam } from "react-icons/bs";
import { PurchaseLineItemData } from "../../../../api/purchase_line_item";
import { Editable, RecordLink } from "@sciencecorp/helix-components";

export const InventoryPurchaseLineItemInput = ({ purchaseLineItem, updateLineItem }) => {
  const inventoryOptions = useInventoryOptions();
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [search, setSearch] = useState(purchaseLineItem.item_name);
  const [options, setOptions] = useState(inventoryOptions);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setOptions(inventoryOptions);
    } else {
      setOptions(
        inventoryOptions.filter((option) =>
          option.label.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      updateLineItem({ id: purchaseLineItem.id, item_name: e.target.value });
    }
  };

  const handleButtonClick = (option) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    updateLineItem({
      id: purchaseLineItem.id,
      item_name: option.label,
      inventory_id: option.value,
    });
    setSearch(option.label);
    onClose();
  };

  return (
    <Popover
      autoFocus={false}
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      matchWidth>
      <PopoverAnchor>
        <HStack>
          <Icon as={BsBoxSeam} />
          <Input
            border="1px"
            borderColor="chakra-border-color"
            data-testid="purchase-line-item-name-input"
            width={"100%"}
            ref={inputRef}
            size="sm"
            variant={"ghost"}
            bg={useColorModeValue("gray.50", "gray.800")}
            type="select"
            onFocus={onOpen}
            placeholder="Search for inventory"
            value={search}
            onBlur={(e) => {
              if (!popoverRef.current?.contains(e.relatedTarget)) {
                onClose();
              }
            }}
            onChange={(e) => handleSearchChange(e)}
          />
        </HStack>
      </PopoverAnchor>
      <PopoverContent
        minWidth={"100%"}
        w="max-content"
        data-testid={`purchase-line-item-inventory-popover-content`}>
        <VStack
          align="start"
          w={"100%"}
          maxH={"50vh"}
          py={2}
          mt={2}
          overflowY={"scroll"}
          overflowX={"hidden"}
          textOverflow={"ellipsis"}
          gap={0}>
          {options.map((option) => (
            <Menu>
              <MenuItem
                data-testid={`purchase-line-item-inventory-option-${option.value}`}
                key={`${option.value} ${option.label}`}
                py={3}
                justifyContent="start"
                bg="transparent"
                width="100%"
                icon={<BsBoxSeam />}
                onClick={() => handleButtonClick(option)}
                _hover={{ bg: useColorModeValue("gray.100", "gray.600") }}>
                {option.label}
              </MenuItem>
            </Menu>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

interface EditablePurchaseLineItemSelectProps {
  onSubmit: (value: PurchaseLineItemData) => void;
  defaultValue: PurchaseLineItemData;
  preview?: React.ReactNode;
  disabled?: boolean;
}

export const EditablePurchaseLineItemSelect = ({
  onSubmit,
  defaultValue,
  disabled = false,
}: EditablePurchaseLineItemSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<PurchaseLineItemData>(defaultValue);

  const handleSubmit = (value: PurchaseLineItemData) => {
    if (!value.item_name) {
      return;
    }
    const inventoryId = value.inventory_id || null;
    onSubmit({ ...value, inventory_id: inventoryId });
  };

  return (
    <Editable
      minWidth={"100%"}
      onSubmit={() => {
        handleSubmit(selectedValue);
      }}
      disabled={disabled}
      preview={
        defaultValue.inventory_id ? (
          <RecordLink
            icon={<BsBoxSeam />}
            identifier={defaultValue.item_name}
            link={`/inventory/items/${defaultValue.inventory_id}/details`}
          />
        ) : (
          <HStack>
            <BsBoxSeam />
            <Text>{defaultValue.item_name}</Text>
          </HStack>
        )
      }
      renderInput={(_value, _setValue, _submit) => {
        return (
          <InventoryPurchaseLineItemInput
            purchaseLineItem={defaultValue}
            updateLineItem={setSelectedValue}
          />
        );
      }}
    />
  );
};
