import React, { useEffect } from "react";
import { VStack, useToast, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import { Controller, useForm } from "react-hook-form";
import * as Errors from "../../../../helpers/errors";
import { required } from "../../../../helpers/validation";
import InputFormControl from "../../../shared/form/InputFormControl";
import {
  VendorContactShowData,
  useNewVendorContact,
  useUpdateVendorContact,
} from "../../../../api/vendor_contacts";
import { VendorData } from "../../../../api/vendor";
import TextareaFormControl from "../../../shared/form/TextareaFormControl";

type DefaultValueTypes = {
  name: string;
  email: string;
  phone_number: string;
  additional_information: string;
  remittance_address: string;
};

const defaultValues: DefaultValueTypes = {
  name: "",
  email: "",
  phone_number: "",
  additional_information: "",
  remittance_address: "",
};

type NewContactModalProps = {
  vendor: VendorData;
  isOpen: boolean;
  onClose: () => void;
  contact?: VendorContactShowData;
};

export const NewVendorContactModal = ({
  vendor,
  isOpen,
  onClose,
  contact,
}: NewContactModalProps) => {
  const { mutate: createVendorContact, isLoading, isError } = useNewVendorContact(vendor.id);
  const {
    mutate: updateVendorContact,
    isLoading: isUpdating,
    isError: isUpdateError,
  } = useUpdateVendorContact(vendor.id);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues: contact
      ? {
          name: contact.name,
          email: contact.email,
          phone_number: contact.phone_number,
          additional_information: contact.additional_information ?? "",
          remittance_address: contact.remittance_address ?? "",
        }
      : defaultValues,
  });

  useEffect(() => {
    if (contact) {
      reset({
        name: contact.name,
        email: contact.email,
        phone_number: contact.phone_number,
        additional_information: contact.additional_information ?? "",
        remittance_address: contact.remittance_address ?? "",
      });
    }
  }, [contact, isOpen]);

  const closeModal = () => {
    reset(defaultValues);
    onClose();
  };
  const toast = useToast();
  const onSubmit = handleSubmit((data) => {
    contact
      ? updateVendorContact(
          {
            id: contact?.id,
            ...data,
          },
          {
            onSuccess: () => {
              closeModal();
              toast({ title: "Vendor contact updated" });
            },
          }
        )
      : createVendorContact(
          {
            vendor_id: vendor.id,
            ...data,
          },
          {
            onSuccess: () => {
              closeModal();
              toast({ title: "Vendor contact added" });
            },
          }
        );
  });

  return (
    <>
      <FormModal
        size="xl"
        title={contact ? "Edit Vendor Contact" : "New Vendor Contact"}
        isOpen={isOpen}
        onClose={closeModal}
        closeOnOverlayClick={false}
        submitButtonTitle={contact ? "Update" : "Create"}
        submitButtonDisabled={isLoading || !isDirty || Object.keys(errors).length > 0}
        submitButtonColorSchema="teal"
        handleSubmit={onSubmit}
        errorMessage={isError ? Errors.generic : undefined}
        isDirty={isDirty}>
        <VStack align="start" width="100%" spacing={6}>
          <Controller
            name="name"
            control={control}
            rules={{
              required,
            }}
            render={({ field }) => (
              <InputFormControl
                label="Contact Name"
                placeholder="Type Contact Name"
                error={errors?.name?.message}
                isRequired
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input {...field} placeholder="Type Email" />
              </FormControl>
            )}
          />
          <Controller
            name="phone_number"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel>Phone Number</FormLabel>
                <Input {...field} placeholder="Type phone number" />
              </FormControl>
            )}
          />
          <Controller
            name="remittance_address"
            control={control}
            render={({ field }) => (
              <TextareaFormControl
                label="Remittance Address"
                placeholder="Remittance Address"
                error={errors?.remittance_address?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="additional_information"
            control={control}
            render={({ field }) => (
              <TextareaFormControl
                label="Additional Information"
                placeholder="Additional Information"
                error={errors?.additional_information?.message}
                {...field}
              />
            )}
          />
        </VStack>
      </FormModal>
    </>
  );
};
