import React, { useMemo, useState } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Input,
  Stack,
  useDisclosure,
  useToast,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { CredentialPackageShowData } from "../../../../api/credential_packages";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";

import { useTeamOptions, useUserOptions } from "../../../../api/options";
import { TeamAndEmployeeSelector } from "./TeamAndEmployeeSelector";
import { useAssociateUsersToCredentialPackage } from "../../../../api/credential_package_users";

type NewCredentialPackageCredentialProps = {
  credentialPackage: CredentialPackageShowData;
};

export const AddCredentialPackageUser = ({
  credentialPackage,
}: NewCredentialPackageCredentialProps) => {
  const { mutate: associateUsers, isLoading } = useAssociateUsersToCredentialPackage();

  const [selectedTeams, setSelectedTeams] = useState<{ id: number; name: string }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<{ id: number; name: string }[]>([]);
  const toast = useToast();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const teamOptions = useTeamOptions();
  const userOptions = useUserOptions(credentialPackage.assigned_user_ids);

  const onSubmit = () => {
    const teamIds = selectedTeams.map((team) => team.id);
    const userIds = selectedUsers.map((user) => user.id);
    if (selectedTeams.length > 0 || selectedUsers.length > 0) {
      associateUsers(
        {
          credential_package_id: credentialPackage.id,
          team_ids: teamIds,
          user_ids: userIds,
        },
        {
          onSuccess: () => {
            toast({
              title: "Employees assigned to package.",
              status: "success",
              duration: 5000,
            });
            handleClose();
          },
          onError: (error) => {
            toast({
              title: "Error assigning employees to package.",
              description: error instanceof Error ? error.message : "An error occurred",
              status: "error",
              duration: 5000,
            });
          },
        }
      );
    }
  };

  const handleClose = () => {
    setSelectedTeams([]);
    setSelectedUsers([]);
    onClose();
  };

  return (
    <>
      <Tooltip
        label="Add credentials/publish package to begin assigning employees"
        isDisabled={credentialPackage.credential_package_credentials.length > 0}>
        <IconButton
          onClick={onOpen}
          aria-label="Add Credential"
          icon={<AddIcon />}
          size="sm"
          isDisabled={
            credentialPackage.credential_package_credentials.length === 0 ||
            credentialPackage.is_draft
          }
          colorScheme="gray"
        />
      </Tooltip>
      <FormModal
        title="Assign Employees"
        submitButtonColorSchema="teal"
        submitButtonTitle="Submit"
        closeOnOverlayClick={false}
        size="lg"
        isOpen={isOpen}
        submitButtonDisabled={selectedTeams.length === 0 && selectedUsers.length === 0}
        isLoading={isLoading}
        onClose={handleClose}
        handleSubmit={onSubmit}>
        <Flex direction="column" width="100%" gap={2}>
          <Flex direction="column" gap={2}>
            {selectedTeams.length > 0 && <Text fontWeight="semibold">Teams</Text>}
            <Stack direction="row" spacing={3} wrap="wrap" w="100%">
              {selectedTeams.map((team) => (
                <HStack
                  w="max-content"
                  p={2}
                  borderRadius="md"
                  key={team.id}
                  bg={useColorModeValue("gray.100", "gray.600")}>
                  <Text fontSize="sm" fontWeight="semibold">
                    {team.name}
                  </Text>
                  <IconButton
                    size="xs"
                    variant="ghost"
                    aria-label="Remove Team"
                    icon={<CloseIcon />}
                    onClick={() =>
                      setSelectedTeams((prev) =>
                        prev.filter((selectedTeam) => selectedTeam.id !== team.id)
                      )
                    }
                  />
                </HStack>
              ))}
            </Stack>
          </Flex>
          <Flex direction="column" gap={2}>
            {selectedUsers.length > 0 && <Text fontWeight="semibold">Users</Text>}
            <Stack direction="row" spacing={3} wrap="wrap" w="100%">
              {selectedUsers.map((user) => (
                <HStack
                  w="max-content"
                  p={2}
                  borderRadius="md"
                  key={user.id}
                  bg={useColorModeValue("gray.100", "gray.600")}>
                  <Text fontSize="sm" fontWeight="semibold">
                    {user.name}
                  </Text>
                  <IconButton
                    size="xs"
                    variant="ghost"
                    aria-label="Remove Team"
                    icon={<CloseIcon />}
                    onClick={() =>
                      setSelectedUsers((prev) =>
                        prev.filter((selectedUser) => selectedUser.id !== user.id)
                      )
                    }
                  />
                </HStack>
              ))}
            </Stack>
          </Flex>
          <TeamAndEmployeeSelector
            setSelectedTeams={setSelectedTeams}
            selectedTeams={selectedTeams}
            teamOptions={teamOptions}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            userOptions={userOptions}
          />
        </Flex>
      </FormModal>
    </>
  );
};
