import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  HStack,
  useColorModeValue,
  useToast,
  Tag,
  Divider,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { Money } from "../../../../helpers/Money";
import { BudgetGroupShowData, useRequestFundingBudgetGroup } from "../../../../api/budget_groups";
import { MoneyInput } from "../../../MoneyInput";
import { MoneyText } from "../../../MoneyText";
import { humanize } from "inflection";

type FormValuesRequestMoreFundingBudgetGroup = {
  requested_amount: Money;
  message: string;
};

type RequestMoreFundingBudgetGroupModalProps = {
  isOpen: boolean;
  onClose: () => void;
  budgetGroup: BudgetGroupShowData;
};

export const RequestMoreFundingBudgetGroupModal = ({
  isOpen,
  onClose,
  budgetGroup,
}: RequestMoreFundingBudgetGroupModalProps) => {
  const { control, watch, reset } = useForm<FormValuesRequestMoreFundingBudgetGroup>({});
  const formValues = watch();
  const { mutateAsync: requestFundingBudgetGroup, isLoading } = useRequestFundingBudgetGroup();

  const borderGray = useColorModeValue("gray.200", "gray.500");
  const lightGrayColor = useColorModeValue("gray.500", "gray.200");
  const backgroundColorGray = useColorModeValue("gray.50", "gray.700");

  const toast = useToast();

  const handleSubmit = () => {
    requestFundingBudgetGroup({
      id: budgetGroup.id,
      amount: formValues.requested_amount,
      message: formValues.message,
    })
      .then(() => {
        toast({
          title: "Request submitted",
          description: "The request has been submitted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        handleClose();
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const disableSubmitButton =
    !formValues.requested_amount || formValues.requested_amount.isZero() || !formValues.message;

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <FormModal
      title="Request More Funding"
      submitButtonColorSchema="teal"
      closeOnOverlayClick={false}
      size="2xl"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleClose}
      submitButtonDisabled={disableSubmitButton}
      handleSubmit={handleSubmit}>
      <Flex
        width={"100%"}
        border={"1px"}
        borderColor={borderGray}
        p={"4"}
        borderRadius={"md"}
        direction={"column"}
        textAlign={"center"}
        boxShadow={"md"}>
        <Text color={lightGrayColor}>{humanize(budgetGroup.name)}'s Available Balance</Text>
        <MoneyText
          as="span"
          fontSize="2xl"
          fontWeight="semibold"
          money={budgetGroup.summary.remaining_amount}
          formatOptions={{ compact: "never", hideCents: true }}
        />
      </Flex>
      <Flex
        direction={"column"}
        width={"100%"}
        p={"4"}
        bgColor={backgroundColorGray}
        borderRadius={"md"}>
        <HStack justifyContent={"space-between"} p={"2"}>
          <Text>Current Allocation</Text>
          <MoneyText
            as="span"
            fontSize="md"
            fontWeight="semibold"
            money={budgetGroup.summary.funding_amount}
            formatOptions={{ compact: "never", hideCents: false }}
          />
        </HStack>
        <Divider color={borderGray} />
        <HStack justifyContent={"space-between"} p={"2"}>
          <Text>Duration</Text>
          <Flex gap={1}>
            <Tag
              colorScheme={
                budgetGroup.budget_group_schedule.schedule_type === "monthly" ? "blue" : "green"
              }>
              {humanize(budgetGroup.budget_group_schedule.schedule_type)}
            </Tag>
            <Box as="span" color={lightGrayColor}>
              {budgetGroup.budget_group_schedule.start_date?.toFormat("LLL dd yyyy")} -
              {budgetGroup.budget_group_schedule.end_date?.toFormat("LLL dd yyyy")}
            </Box>
          </Flex>
        </HStack>
      </Flex>
      <Controller
        name="requested_amount"
        control={control}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>How much funding do you require for this budget group?</FormLabel>
            <MoneyInput {...field} />
          </FormControl>
        )}
      />
      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea {...field} />
          </FormControl>
        )}
      />
    </FormModal>
  );
};
