import React, { useEffect, useState } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Avatar,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SiteCreateParams, SitePartialData, deleteSiteUser } from "../../api/sites";
import { Controller, set, useForm } from "react-hook-form";
import { useUserOptions } from "../../api/options";
import { useUserQuery } from "../../api/user";
import { CloseIcon } from "@chakra-ui/icons";
import {
  useCreateSiteQuery,
  useCreateSiteUserQuery,
  useUpdateSiteQuery,
  useDeleteSiteUserQuery,
} from "../../api/sites";

type SiteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  site: SitePartialData | null;
  setAlertShow: React.Dispatch<React.SetStateAction<string>>;
  setActiveSite: React.Dispatch<React.SetStateAction<SitePartialData | null>>;
};

const defaultValues = { name: "", site_address: "", site_city: "", site_state: "" };
export const SiteModal = ({
  isOpen,
  onClose,
  site,
  setAlertShow,
  setActiveSite,
}: SiteModalProps) => {
  const { mutate: createSite } = useCreateSiteQuery();
  const { mutate: updateSite } = useUpdateSiteQuery();
  const { mutate: createSiteUser } = useCreateSiteUserQuery();
  const { mutate: deleteSiteUser } = useDeleteSiteUserQuery();
  const [members, setMembers] = useState<number[]>([]);
  const allUserOptions = useUserOptions();
  const [session, setSession] = useState<boolean>(false);
  const [userOptions, setUserOptions] = useState(allUserOptions);
  const { reset, control, handleSubmit } = useForm({ defaultValues });

  const handleClose = () => {
    onClose();
    reset(defaultValues);
    setActiveSite(null);
    setMembers([]);
  };
  useEffect(() => {
    if (allUserOptions.length) setSession(true);
  }, [allUserOptions]);

  useEffect(() => {
    if (site) {
      setUserOptions(allUserOptions.filter((user) => !members.includes(Number(user.value))));
    }
    setUserOptions(allUserOptions);
  }, [session]);

  useEffect(() => {
    if (site && site.site_users) {
      reset({
        name: site.name,
        site_address: site.site_address,
        site_city: site.site_city,
        site_state: site.site_state,
      });
      const memberIds = site.site_users.map((member) => member.user_id);
      setUserOptions(allUserOptions.filter((user) => !memberIds.includes(Number(user.value))));

      setMembers(memberIds);
    }
  }, [site, reset]);

  const handleChange = (id) => {
    setMembers((prev) => [...prev, id]);
    setUserOptions((prev) => prev.filter((user) => user.value !== id));
  };

  const handleDelete = (id: string, name: string) => {
    setMembers((prev) => prev.filter((member) => member !== Number(id)));
    setUserOptions((prev) => [...prev, { value: id, label: name }]);
  };

  const onSubmit = (formData: SiteCreateParams) => {
    if (site) {
      updateSite(
        { ...formData, id: site.id },
        {
          onSuccess: () => {
            const newMembers = members.filter(
              (member) => !site.site_users.map((user) => user.user_id).includes(Number(member))
            );
            const deletedMembers = site.site_users.filter(
              (siteUser) => !members.includes(siteUser.user_id)
            );
            newMembers.forEach((member) => {
              createSiteUser({ site_id: site.id, user_id: member });
            });
            deletedMembers.forEach((member) => {
              deleteSiteUser(member.id);
            });
            handleClose();
            reset(defaultValues);
            setAlertShow(`You have successfully edited ${site.name}`);
          },
        }
      );
    } else {
      createSite(formData, {
        onSuccess: ({ data }) => {
          members.forEach((member) => {
            createSiteUser({ site_id: data.id, user_id: member });
          });
          handleClose();
          reset(defaultValues);
          setAlertShow(`You have created a new site`);
        },
      });
    }
  };

  return (
    <FormModal
      title={site ? "Edit Site" : "Create Site"}
      isOpen={isOpen}
      onClose={handleClose}
      submitButtonTitle={site ? "Update" : "Create"}
      submitButtonColorSchema="teal"
      size="2xl"
      closeOnOverlayClick={false}
      handleSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Site Name</FormLabel>
            <Input {...field} placeholder="Site Name" />
          </FormControl>
        )}
      />
      <Controller
        name="site_address"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input {...field} placeholder="Street Address" />
          </FormControl>
        )}
      />
      <HStack width="100%">
        <Controller
          name="site_city"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input {...field} placeholder="City" />
            </FormControl>
          )}
        />
        <Controller
          name="site_state"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>State</FormLabel>
              <Input {...field} placeholder="CA, NC" />
            </FormControl>
          )}
        />
      </HStack>
      <FormControl>
        <FormLabel>Add Employee</FormLabel>
        <Select
          width="100%"
          placeholder="Select Employee"
          options={userOptions}
          onChange={(value) => handleChange(value)}
        />
      </FormControl>
      <Stack width="100%" align="start" direction="column">
        <Text fontSize="xs" fontWeight="semibold">
          Employees {`(${members.length})`}
        </Text>
        <Flex wrap="wrap" gap={2}>
          {members.map((member) => (
            <EmployeeCard key={member} employeeId={member} handleDelete={handleDelete} />
          ))}
        </Flex>
      </Stack>
    </FormModal>
  );
};

const EmployeeCard = ({ employeeId, handleDelete }) => {
  const { data: user } = useUserQuery(employeeId);
  return (
    <Flex align="center" gap={1.5}>
      <Avatar src={user?.picture_uri} size="sm" />
      <Text fontSize="sm" fontWeight="semibold">
        {user?.name}
      </Text>
      <IconButton
        aria-label="delete employee"
        size="xs"
        bg="none"
        onClick={() => handleDelete(employeeId, user?.name)}
        icon={<CloseIcon boxSize={2.5} color="red.500" />}
      />
    </Flex>
  );
};
