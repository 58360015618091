import React, { useState, useCallback } from "react";
import {
  Box,
  Text,
  Checkbox,
  Td,
  Tr,
  Thead,
  Tbody,
  Th,
  IconButton,
  Flex,
  Spinner,
  Table,
  Input,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { groupBy, orderBy } from "lodash";
import { InventoryLocationShowData } from "../../../api/inventory_location";
import { useUpdateInventoryLocation } from "../../../api/inventory_location";
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";
import { LocationActionsMenu } from "./components/LocationActionsMenu";
import { SearchResponse } from "../../../api/shared";

export interface LocationsProps {
  isLoading: boolean;
  isSuccess: boolean;
  data: SearchResponse<InventoryLocationShowData> | undefined;
  isInventoryManager: boolean;
}

export const Locations = ({ isLoading, isSuccess, data, isInventoryManager }: LocationsProps) => {
  const updateLocationMutation = useUpdateInventoryLocation();

  if (isLoading) {
    return <Spinner />;
  } else if (isSuccess) {
    // Defensive check
    if (!data || !data.results) {
      console.error("Invalid data structure:", data);
      return <Text>No locations found.</Text>;
    }

    const nestedLocations = orderBy(nestLocations(data.results), "name");

    return (
      <>
        <Box w="100%" overflowX="scroll">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Location Name</Th>
                <Th textAlign={"center"}>Capacity for Storage</Th>
                <Th textAlign={"center"}>No. of Items</Th>
                <Th textAlign={"center"}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {nestedLocations.map((location) => (
                <LocationRow
                  key={`location-${location.id}-${location.name}`}
                  location={location}
                  updateLocationMutation={updateLocationMutation}
                  isInventoryManager={isInventoryManager}
                />
              ))}
            </Tbody>
          </Table>
        </Box>
      </>
    );
  } else {
    return <Text>Error loading locations.</Text>;
  }
};

type NestedLocationData = InventoryLocationShowData & { childrenLocations: NestedLocationData[] };

interface LocationRowProps {
  location: NestedLocationData;
  updateLocationMutation: ReturnType<typeof useUpdateInventoryLocation>;
  depth?: number;
  isInventoryManager: boolean;
}

const LocationRow = ({
  location,
  depth = 0,
  updateLocationMutation,
  isInventoryManager,
}: LocationRowProps): JSX.Element => {
  const [isRowOpen, setIsRowOpen] = useState(false);

  const hasChildren = location.childrenLocations.length > 0;
  const toast = useToast();

  const toggleExpand = useCallback(
    (value?: boolean) => {
      if (value !== undefined) setIsRowOpen(value);
      else setIsRowOpen(!isRowOpen);
    },
    [isRowOpen, setIsRowOpen]
  );

  const calculateTotalInventoryCount = (location: NestedLocationData): number => {
    return (
      location.inventory_item_count +
      location.childrenLocations.reduce(
        (sum, child) => sum + calculateTotalInventoryCount(child),
        0
      )
    );
  };

  const [editing, setEditing] = useState(location.name === "");
  const { mutate: updateLocation, isLoading } = updateLocationMutation;
  const [success, setSuccess] = useState(false);
  const handleSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  return (
    <>
      <Tr key={`location-${location.id}`} overflow={"scroll"}>
        <Td
          pl={`${depth * 2}rem`}
          onClick={location.childrenLocations.length > 0 ? () => toggleExpand() : undefined}
          _hover={{ cursor: hasChildren ? "pointer" : "auto" }}>
          <Flex gap={1}>
            <IconButton
              visibility={hasChildren ? "visible" : "hidden"}
              size="mg"
              variant="ghost"
              aria-label="Expand team"
              icon={isRowOpen ? <MdOutlineArrowDropDown /> : <MdOutlineArrowRight />}
              onClick={() => toggleExpand()}
            />

            {editing ? (
              <Input
                size="xs"
                defaultValue={location.name}
                onBlur={(e) => {
                  if (e.target.value) {
                    updateLocation(
                      { id: location.id, name: e.target.value },
                      {
                        onSuccess: () => {
                          setEditing(false);
                        },
                        onError: (e) => {
                          toast({
                            title: "Error updating location",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                        },
                      }
                    );
                  } else {
                    toast({
                      title: "Location name cannot be empty",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                }}
              />
            ) : (
              <Text fontSize="sm" fontWeight="semibold">
                {location.name}
              </Text>
            )}
          </Flex>
        </Td>

        <Td textAlign="center">
          <Tooltip label="Success!" isOpen={success}>
            <Checkbox
              isDisabled={
                !isInventoryManager ||
                isLoading ||
                (location.has_inventory_items && location.capacity_for_storage)
              }
              colorScheme="teal"
              isChecked={location.capacity_for_storage}
              onChange={(e) => {
                updateLocation(
                  { id: location.id, capacity_for_storage: e.target.checked },
                  {
                    onSuccess: () => {
                      handleSuccess();
                    },
                  }
                );
              }}
            />
          </Tooltip>
        </Td>

        <Td textAlign="center">
          {isRowOpen ? location.inventory_item_count : calculateTotalInventoryCount(location)}
        </Td>

        {isInventoryManager ? (
          <Td textAlign="center">
            <LocationActionsMenu
              location={location}
              setEditing={setEditing}
              setIsRowOpen={() => toggleExpand(true)}
            />
          </Td>
        ) : (
          <Td></Td>
        )}
      </Tr>

      {isRowOpen &&
        location.childrenLocations.map((childLocation) => {
          return (
            <LocationRow
              key={`child-location-${childLocation.id}`}
              location={childLocation}
              depth={depth + 1}
              isInventoryManager={isInventoryManager}
              updateLocationMutation={updateLocationMutation}
            />
          );
        })}
    </>
  );
};

const nestLocations = (locations: InventoryLocationShowData[]) => {
  if (!locations || locations.length === 0) {
    return [];
  }

  const locationGroups = groupBy(locations, (location) =>
    location.parent_inventory_location_id === null
      ? "null"
      : location.parent_inventory_location_id.toString()
  );

  const nest = (location: InventoryLocationShowData) => {
    const children = orderBy(locationGroups[location.id.toString()], "name") || [];

    return { ...location, childrenLocations: children.map(nest) };
  };

  const topLevelLocations = locationGroups["null"];
  return topLevelLocations ? topLevelLocations.map(nest) : [];
};
