import { FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateInventoryBom } from "../../../api/inventory_boms";
import { useNavigate } from "react-router";
import { useActiveUsersQuery } from "../../../api/user";
import { Select as MultiSelect } from "chakra-react-select";

type NewBOMModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormValuesNewBOMModalType = {
  name: string;
  description: string;
  inventory_bom_users: number[];
};

export const NewBOMModal = ({ isOpen, onClose }: NewBOMModalProps) => {
  const navigate = useNavigate();
  const { control, handleSubmit, watch, setValue, reset } = useForm<FormValuesNewBOMModalType>({
    defaultValues: {
      name: "",
      description: "",
      inventory_bom_users: [],
    },
  });
  const { data: users } = useActiveUsersQuery();
  const { mutateAsync: createInventoryBom, isLoading: isCreateBomLoading } =
    useCreateInventoryBom();
  const formValues = watch();
  const handleClose = () => {
    reset();
    onClose();
  };

  const handleDisable =
    !formValues.name || !formValues.description || formValues.inventory_bom_users.length === 0;

  const onSubmit = (formValues: FormValuesNewBOMModalType) => {
    createInventoryBom({
      name: formValues.name,
      description: formValues.description,
      inventory_bom_users: formValues.inventory_bom_users,
    }).then((data) => {
      handleClose();
      navigate(`/inventory/boms/${data.id}`);
    });
  };

  return (
    <FormModal
      title="Create New Bill of Materials"
      submitButtonColorSchema="teal"
      closeOnOverlayClick={false}
      submitButtonTitle="Submit"
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
      isLoading={isCreateBomLoading}
      handleSubmit={handleSubmit(onSubmit)}
      submitButtonDisabled={handleDisable}>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Name of the Bill of Materials</FormLabel>
            <Input {...field} />
          </FormControl>
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Description of the Bill of Materials</FormLabel>
            <Textarea {...field} />
          </FormControl>
        )}
      />
      <Controller
        name="inventory_bom_users"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Owners of the Bill of Materials</FormLabel>
            <MultiSelect
              isMulti
              placeholder="Choose Users"
              focusBorderColor="teal.400"
              useBasicStyles
              options={users?.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
              onChange={(selectedOptions) => {
                const listOfOwnerIds = [...selectedOptions.map((option) => option.value)];
                setValue("inventory_bom_users", listOfOwnerIds);
              }}
            />
          </FormControl>
        )}
      />
    </FormModal>
  );
};
