import { Flex, Stack, HStack, useColorModeValue, Switch, Text, Box, Link } from "@chakra-ui/react";
import { EditableText, StatusSelect, EditableSelect } from "@sciencecorp/helix-components";
import React from "react";
import { VendorShowData, useUpdateVendor } from "../../../../api/vendor";
import { vendorStatusOptions, vendorStatusMap } from "../../util";

export const VendorDetails = ({
  vendor,
  isPurchasingAdmin,
}: {
  vendor: VendorShowData;
  isPurchasingAdmin: boolean;
}) => {
  const { mutate: updateVendor, isLoading } = useUpdateVendor(vendor.id);
  return (
    <Flex direction="column" gap={4}>
      <Stack spacing={6} alignItems={"start"} direction={["column", "column", "row"]}>
        {/* Address Section */}
        <Box>
          <HStack alignItems="start">
            <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
              Address
            </Text>
            <EditableText
              multiline
              defaultValue={vendor.address || ""}
              onSubmit={(value) => {
                value && updateVendor({ id: vendor.id, address: value });
              }}
              disabled={!isPurchasingAdmin}
              isLoading={isLoading}
            />
          </HStack>
        </Box>
        <Box>
          <HStack alignItems="center">
            <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
              Website
            </Text>
            {vendor.website ? (
              <Link isTruncated={true}>
                <EditableText
                  defaultValue={vendor.website}
                  onSubmit={(value) => {
                    value && updateVendor({ id: vendor.id, website: value });
                  }}
                  disabled={!isPurchasingAdmin}
                  isLoading={isLoading}
                />
              </Link>
            ) : (
              <EditableText
                defaultValue={vendor.website}
                onSubmit={(value) => {
                  value && updateVendor({ id: vendor.id, website: value });
                }}
                disabled={!isPurchasingAdmin}
              />
            )}
          </HStack>
        </Box>
      </Stack>
      <Stack spacing={6} alignItems={"start"} direction={["column", "column", "row"]}>
        <Box width="full">
          <HStack alignItems="center" w="max-content">
            <Text
              fontSize="sm"
              color={useColorModeValue("gray.600", "gray.400")}
              whiteSpace="nowrap">
              Description
            </Text>
            <EditableText
              defaultValue={vendor.description}
              onSubmit={(value) => {
                value && updateVendor({ id: vendor.id, description: value });
              }}
              disabled={!isPurchasingAdmin}
            />
          </HStack>
        </Box>
      </Stack>
    </Flex>
  );
};
