import {
  Box,
  Flex,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { EmptyState, useCollection } from "@sciencecorp/helix-components";
import React from "react";
import { useSearchPurchasesWithStats } from "../../../../api/purchase";
import { VendorData } from "../../../../api/vendor";
import { PurchasesTab } from "../../PurchasesTab";
import { MoneyText } from "../../../MoneyText";
import { useSearchContracts } from "../../../../api/contracts";
import { ContractsTab } from "../../ContractsTab";

export const VendorTransactionsTable = ({ vendor }: { vendor: VendorData }) => {
  const { pagination, onPagination, order, onOrder } = useCollection();

  const { data: purchases, isLoading } = useSearchPurchasesWithStats({
    term: "*",
    pagination: { per_page: -1 },
    order,
    filters: {
      vendor_id: vendor.id,
    },
  });

  const { data: contracts, isLoading: isLoadingContracts } = useSearchContracts({
    term: "*",
    pagination: { per_page: -1 },
    order,
    filters: {
      vendor_id: vendor.id,
    },
  });

  return (
    <Box w="100%">
      <Tabs colorScheme="teal">
        <TabList>
          <Tab>Purchases</Tab>
          <Tab>Contracts</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              wrap="wrap"
              mb={6}
              p={4}
              bg={useColorModeValue("gray.50", "gray.700")}
              borderRadius="md"
              border="1px"
              borderColor="chakra-border-color">
              <Stat>
                <StatLabel>Pending Approval</StatLabel>
                <StatNumber>
                  <MoneyText
                    money={purchases?.total_pending_approval}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Committed</StatLabel>
                <StatNumber>
                  <MoneyText
                    money={purchases?.total.subtract(purchases?.total_spent)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Spent</StatLabel>
                <StatNumber>
                  <MoneyText money={purchases?.total_spent} formatOptions={{ compact: "never" }} />
                </StatNumber>
              </Stat>
            </Flex>
            {purchases?.results.length ? (
              <PurchasesTab
                data={purchases || []}
                isLoading={isLoading}
                pagination={pagination}
                onPagination={onPagination}
                order={order}
                onOrder={onOrder}
                location="vendors"
              />
            ) : (
              <EmptyState title="No Purchases" />
            )}
          </TabPanel>
          <TabPanel>
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              wrap="wrap"
              mb={6}
              p={4}
              bg={useColorModeValue("gray.50", "gray.700")}
              borderRadius="md"
              border="1px"
              borderColor="chakra-border-color">
              <Stat>
                <StatLabel>Committed</StatLabel>
                <StatNumber>
                  <MoneyText
                    money={contracts?.total.subtract(contracts?.total_paid)}
                    formatOptions={{ compact: "never" }}
                  />
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Spent</StatLabel>
                <StatNumber>
                  <MoneyText money={contracts?.total_paid} formatOptions={{ compact: "never" }} />
                </StatNumber>
              </Stat>
            </Flex>
            {contracts?.results.length ? (
              <ContractsTab
                data={contracts || []}
                isLoading={isLoadingContracts}
                pagination={pagination}
                onPagination={onPagination}
                order={order}
                onOrder={onOrder}
                location="vendors"
              />
            ) : (
              <EmptyState title="No Contracts" />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
