import _ from "lodash";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Currency, Money, supportedCurrencies } from "../helpers/Money";
import { Text, Box, Input, InputGroup, InputLeftAddon, InputProps } from "@chakra-ui/react";
import { Select, StatusSelect } from "@sciencecorp/helix-components";
import Big from "big.js";
import { useCurrency } from "../contexts/CurrencyContext";

export interface MoneyInputProps
  extends Omit<InputProps, "onChange" | "type" | "value" | "onBlur" | "onSubmit" | "min" | "max"> {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  max?: Money;
  min?: Money;
  value?: Money;
  onChange?: (newValue: Money) => void;
  onBlur?: (newValue: Money) => void;
  onSubmit?: (newValue: Money) => void;
}
export const MoneyInput: React.FC<MoneyInputProps> = forwardRef(
  ({ max, size = "md", min, value, onChange, onBlur, onSubmit, name, ...rest }, ref) => {
    const currency = useCurrency(value?.currency?.code);

    if (value?.currency && value?.currency.code !== currency) {
      throw new Error(
        "The currency passed into the MoneyInput component does not match the currency of the value prop"
      );
    }
    const currencyObj = useMemo<Currency>(
      () => value?.currency || supportedCurrencies[currency],
      [currency]
    );
    const minMoney = min ? min : Money.zero(currencyObj);
    const maxMoney = max ? max : Money.fromMinorUnits(Number.MAX_SAFE_INTEGER, currencyObj);

    const [stateValue, setStateValue] = useState<string>(
      value?.majorUnitString() || minMoney.majorUnitString()
    );
    const [isInvalid, setIsInvalid] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const isValid = useMemo(() => {
      try {
        Money.fromMajorUnitString(stateValue, currencyObj);
        return true;
      } catch (e) {
        return false;
      }
    }, [stateValue, currencyObj]);

    useEffect(() => {
      if (value !== undefined && !isEditing) {
        setStateValue(value.majorUnitString());
      }
    }, [value, isEditing]);

    return (
      <Box>
        <InputGroup size={size}>
          <InputLeftAddon>
            <Text data-testid={"money-input-currency-symbol"}>{currencyObj.symbol}</Text>
          </InputLeftAddon>
          <Input
            data-testid={"money-input-amount"}
            {...rest}
            name={`${name}_amount`}
            isInvalid={isInvalid}
            errorBorderColor="red.300"
            focusBorderColor={isInvalid ? "red.300" : undefined}
            ref={ref}
            size={size}
            type={"number"}
            max={maxMoney.majorUnits.toNumber()}
            min={minMoney.majorUnits.toNumber()}
            step={new Big(1).div(currencyObj.subunit_to_unit).toNumber()}
            value={stateValue}
            onWheel={(e) => {
              if (window.document.activeElement === e.target) {
                (window.document.activeElement as HTMLInputElement).blur();
              }
              e.preventDefault();
            }}
            onChange={(e) => {
              setIsEditing(true);
              const stringValue = e.target.value;
              setStateValue(stringValue);
              try {
                setIsInvalid(false);
                const inputMoney = Money.fromMajorUnitString(
                  stringValue === "" ? minMoney.majorUnitString() : stringValue,
                  currencyObj
                );
                if (inputMoney.gt(maxMoney) || inputMoney.lt(minMoney)) {
                  setIsInvalid(true);
                } else {
                  onChange && onChange(inputMoney);
                }
              } catch (e) {
                setIsInvalid(true);
              }
            }}
            onBlur={(e) => {
              setIsEditing(false);
              const stringValue = e.target.value;
              const newValue = Money.fromMajorUnitString(stringValue, currencyObj);
              isValid && onBlur && onBlur(newValue);
            }}
            onSubmit={() => {
              const newValue = Money.fromMajorUnitString(stateValue, currencyObj);
              isValid && onSubmit && onSubmit(newValue);
            }}
          />
        </InputGroup>
      </Box>
    );
  }
);
