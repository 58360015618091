import {
  VStack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormErrorMessage,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import React from "react";
import { Select } from "@sciencecorp/helix-components";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { SpendingAuthoritySelect } from "../../Purchasing/SpendingAuthoritySelectTree";
import { useUserOptions } from "../../../api/options";
import { InventoryItemShowData } from "../../../api/inventory_item";
import { FormValues } from "../inventoryItem";

type SelectSpendingAuthorityProps = {
  formValues: FormValues;
  inventoryItem: InventoryItemShowData;
  setValue: UseFormSetValue<FormValues>;
  control: Control<FormValues, any>;
  isInventoryManager: boolean;
};

export const SelectSpendingAuthority = ({
  formValues,
  inventoryItem,
  setValue,
  control,
  isInventoryManager,
}: SelectSpendingAuthorityProps) => {
  const userOptions = useUserOptions();

  return (
    <VStack gap={4}>
      {formValues.purpose === "spending_authority" && (
        <Controller
          name="spending_authority"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Select a Spending Authority</FormLabel>
              <SpendingAuthoritySelect
                onChange={(spendingAuthority) => {
                  setValue("spending_authority", spendingAuthority);
                }}
                spendingAuthority={field.value || inventoryItem.spending_authority}
              />
            </FormControl>
          )}
        />
      )}
      {inventoryItem.inventory.is_consumable && (
        <Controller
          name="quantity_used"
          control={control}
          rules={{
            required: "Quantity used is required",
            validate: (value) => {
              const parsedValue = parseFloat(value || "0");
              return (
                (!isNaN(parsedValue) && parsedValue >= 0) ||
                "Quantity used must be a non-negative number"
              );
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl isInvalid={!!error} isRequired>
              {(inventoryItem.consumable_summary?.remaining_consumable_quantity || 0) <= 0 && (
                <Alert status="warning" mb={4}>
                  <AlertIcon />
                  This inventory item has no remaining quantity to use.
                </Alert>
              )}
              <FormLabel>
                Quantity {formValues.purpose === "spending_authority" ? "Needed" : "used"} (
                {inventoryItem.consumable_summary?.remaining_consumable_quantity}{" "}
                {inventoryItem.inventory.unit_of_measurement || "units"} Available)
              </FormLabel>
              <NumberInput
                {...field}
                allowMouseWheel={false}
                isDisabled={inventoryItem.consumable_summary?.remaining_consumable_quantity === 0}
                min={0}
                max={
                  Number(inventoryItem.consumable_summary?.remaining_consumable_quantity) || 99999
                }
                value={field.value || "0.0"}
                precision={2}
                onChange={(valueString) => field.onChange(valueString)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            </FormControl>
          )}
        />
      )}
      {isInventoryManager && !inventoryItem.inventory.is_consumable && (
        <Controller
          name="user_id"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Assign a User (optional)</FormLabel>
              <Select placeholder="Select a user" options={userOptions} {...field} />
            </FormControl>
          )}
        />
      )}
    </VStack>
  );
};
