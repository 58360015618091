import _ from "lodash";
import { Alert, Spinner } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { DateTime } from "luxon";
import { Money } from "../../../../helpers/Money";
import {
  PurchasePaymentTransactionData,
  useSearchPurchasePayments,
} from "../../../../api/purchase_payment";
import { Filters } from "../../../../api/collection_types";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const processPayments = (payments: PurchasePaymentTransactionData[]) => {
  return payments.reduce((acc, payment) => {
    if (!payment.paid_at) return acc;
    const month = payment.paid_at.toFormat("MMM yyyy");
    if (!acc[month]) {
      acc[month] = Money.zero(payment.amount.currency);
    }
    acc[month] = acc[month].add(payment.amount);
    return acc;
  }, {} as { [key: string]: Money });
};

export const PaymentsSpendingGraph = ({ filters }: { filters: Filters }) => {
  const { data: purchasePayments, isLoading } = useSearchPurchasePayments({
    currency: "USD",
    term: "*",
    filters: { ...filters, status: "paid" },
    order: { created_at: "desc" },
    pagination: { per_page: -1 },
  });

  const chartData: ChartData<"line", number[], string> | null = useMemo(() => {
    if (!purchasePayments) return null;
    const monthlyTotals = processPayments(purchasePayments.results);
    const sortedMonths = _.orderBy(
      Object.keys(monthlyTotals),
      (month) => DateTime.fromFormat(month, "MMM yyyy").toJSDate(),
      ["asc"]
    );

    const dates = sortedMonths.map((date) => DateTime.fromFormat(date, "MMM yyyy"));
    const startMonth = dates[0];
    const endMonth = DateTime.now().startOf("month");
    const labels: string[] = [];
    const data: Money[] = [];
    for (let month = startMonth; month <= endMonth; month = month.plus({ months: 1 })) {
      const monthLabel = month.toFormat("MMM yyyy");
      labels.push(monthLabel);
      data.push(monthlyTotals[monthLabel] || Money.zero("USD"));
    }

    return {
      labels,
      datasets: [
        {
          label: "Amount Spent",
          data: data.map((d) => d.majorUnits.toNumber()),
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          pointRadius: 3,
          pointHoverRadius: 5,
          pointStyle: "circle",
        },
      ],
    };
  }, [purchasePayments]);

  if (isLoading) return <Spinner />;
  if (!chartData) return <Alert status="info">No data</Alert>;
  return (
    <Line
      data={chartData}
      options={{
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
            position: "bottom",
          },
        },
      }}
    />
  );
};
