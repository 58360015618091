import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import {
  BudgetItemShowData,
  BudgetableData,
  budgetItemSchema,
  budgetItemShowSchema,
} from "./budget_items";
import { revenueItemSchema, revenueItemShowSchema } from "./revenue_items";
import { ServiceRequestShowData, serviceRequestShowSchema } from "./service_requests";
import { SpendingAuthorityTree } from "./spending_authority_tree";
import { zodParse } from "./zodParse";

export const spendingAuthorityTypeSchema = z.enum(["BudgetItem", "ServiceRequest", "RevenueItem"]);

export const spendingAuthorityShowSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("BudgetItem") }).extend(budgetItemShowSchema.shape),
  z.object({ type: z.literal("RevenueItem") }).extend(revenueItemShowSchema.shape),
]);
export const spendingAuthoritySchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("BudgetItem") }).extend(budgetItemSchema.shape),
  z.object({ type: z.literal("RevenueItem") }).extend(revenueItemSchema.shape),
]);

export interface SpendingAuthorityReference {
  spending_authority_id: number;
  spending_authority_type: string;
}

export const minimalSpendingAuthoritySchema = z.object({
  id: z.number(),
  type: z.enum(["BudgetItem", "ServiceRequest", "RevenueItem"]),
  name: z.string(),
});

export type SpendingAuthorityShowData = z.infer<typeof spendingAuthorityShowSchema>;
export type SpendingAuthorityData = z.infer<typeof spendingAuthoritySchema>;
export type MinimalSpendingAuthority = z.infer<typeof minimalSpendingAuthoritySchema>;
export type NullableSpendingAuthority = MinimalSpendingAuthority | null;

export const getSpendingAuthority = async (
  id?: number,
  type?: MinimalSpendingAuthority["type"]
) => {
  let result;
  switch (type) {
    case "BudgetItem":
      result = await api.get(`budget_items/${id}`);
      return zodParse(budgetItemShowSchema, result.data);
    case "ServiceRequest":
      result = await api.get(`service_requests/${id}`);
      return zodParse(serviceRequestShowSchema, result.data);
  }
};

export interface SpendingAuthorityRecordLinkProps {
  link: string;
  identifier: string;
  type: SpendingAuthorityTree["type"];
  rootSpendingAuthority: SpendingAuthorityShowData | null;
  source_of_authority?: BudgetableData | null;
  budget_group?: BudgetItemShowData["budget_group"];
}

export const useGetSpendingAuthority = (
  id: number | undefined | null,
  type: MinimalSpendingAuthority["type"] | undefined | null
): UseQueryResult<SpendingAuthorityRecordLinkProps> => {
  return useQuery({
    enabled: !!id && !!type,
    queryKey: ["spending_authority", id, type],
    queryFn: async (): Promise<SpendingAuthorityRecordLinkProps> => {
      const result = await getSpendingAuthority(id!, type!);

      switch (type) {
        case "BudgetItem":
          const budgetItemResult = result as BudgetItemShowData;
          return {
            link: budgetItemResult.app_href,
            identifier: budgetItemResult.item_name,
            type: budgetItemResult.budgetable!.type,
            rootSpendingAuthority: { type: "BudgetItem", ...budgetItemResult },
            source_of_authority: budgetItemResult.budgetable,
            budget_group: budgetItemResult.budget_group,
          };
        case "ServiceRequest":
          const serviceRequestResult = result as ServiceRequestShowData;
          return {
            link: serviceRequestResult.app_href,
            identifier: serviceRequestResult.request_description,
            type: "Service",
            rootSpendingAuthority: serviceRequestResult.root_spending_authority,
            source_of_authority: serviceRequestResult.budgetable,
          };
        default:
          throw new Error(`Unknown spending authority type ${type}`);
      }
    },
  });
};
