import React, { useMemo } from "react";
import _ from "lodash";

import { Controller, useForm } from "react-hook-form";
import { Project } from "../../../api/planning/projects";
import {
  ProjectCheckpoint,
  useAddProjectCheckpoint,
  useUpdateProjectCheckpoint,
} from "../../../api/planning/project_checkpoints";
import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { FormModal, Select, SelectOption } from "@sciencecorp/helix-components";

interface CheckpointFormValues {
  title: string;
  referencePoint: SelectOption["value"];
  offsetValue: number;
  offsetPeriod: string;
}
interface CheckpointFormModalProps {
  project: Project;
  checkpoints: ProjectCheckpoint[];
  onClose: () => void;
  isOpen: boolean;
  checkpoint?: ProjectCheckpoint | null;
}

export const CheckpointFormModal: React.FC<CheckpointFormModalProps> = ({
  onClose,
  isOpen,
  project,
  checkpoints,
  checkpoint,
}: CheckpointFormModalProps) => {
  const PROJECT_START_KEY = "start";
  const editMode = checkpoint !== null;

  const { mutate: addCheckpoint } = useAddProjectCheckpoint();
  const { mutate: updateCheckpoint } = useUpdateProjectCheckpoint();

  const checkpointsMapById: Record<number, ProjectCheckpoint | undefined> = useMemo(() => {
    return _.keyBy(checkpoints, "id");
  }, [checkpoints]);

  const findAllDependencies = (checkpoint, checkpointsMap, dependencies = new Set()) => {
    if (checkpoint === undefined) {
      return dependencies;
    }
    return findAllDependencies(
      checkpointsMap[checkpoint.previous_checkpoint.id],
      checkpointsMap,
      dependencies.add(checkpoint.previous_checkpoint.id)
    );
  };

  const excludeCheckpointsWithDependency = (
    excludeCheckpointId: number,
    checkpoints: ProjectCheckpoint[],
    checkpointsMap: Record<number, ProjectCheckpoint | undefined>
  ) => {
    return checkpoints.filter((checkpoint) => {
      const dependencies = findAllDependencies(checkpoint, checkpointsMap);
      return !dependencies.has(excludeCheckpointId);
    });
  };

  const formDefaultValues = editMode
    ? {
        title: checkpoint?.title,
        referencePoint: checkpoint?.previous_checkpoint?.id || PROJECT_START_KEY,
        offsetValue: checkpoint?.offset_days || 0,
        offsetPeriod: "days",
      }
    : {
        title: "",
        referencePoint: PROJECT_START_KEY,
        offsetValue: 0,
        offsetPeriod: "days",
      };

  const { handleSubmit, control, reset, watch } = useForm<CheckpointFormValues>({
    defaultValues: formDefaultValues,
  });

  React.useEffect(() => {
    reset(formDefaultValues);
  }, [checkpoint, reset]);

  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const calcOffset = (val: number, period: string): number => {
    switch (period) {
      case "days":
        return val;
      case "weeks":
        return val * 7;
      case "months":
        return val * 30;
      default:
        return val;
    }
  };

  const onSubmit = (checkpointData: CheckpointFormValues) => {
    const { title, offsetValue, offsetPeriod, referencePoint } = checkpointData;
    const referencePointId = referencePoint === PROJECT_START_KEY ? null : Number(referencePoint);
    const offsetDays = Number(calcOffset(offsetValue, offsetPeriod));
    if (title != "" && offsetValue >= 0 && offsetPeriod != "" && referencePoint != "") {
      if (editMode && checkpoint) {
        updateCheckpoint({
          id: checkpoint.id,
          project_id: project.id,
          title: title,
          offset_days: offsetDays,
          previous_checkpoint_id: referencePointId,
        });
      } else {
        addCheckpoint({
          project_id: project.id,
          title: title,
          offset_days: offsetDays,
          previous_checkpoint_id: referencePointId,
        });
      }
      handleClose();
    }
  };

  let availableReferencePoints: SelectOption[] = [
    { label: project.title + " Start", value: PROJECT_START_KEY },
  ];
  if (checkpoints.length > 0) {
    const filteredCheckpoints = checkpoints.filter((cp) => cp.id !== checkpoint?.id);
    const appropriateCheckpoints = excludeCheckpointsWithDependency(
      checkpoint?.id!,
      filteredCheckpoints,
      checkpointsMapById
    );
    availableReferencePoints.push(
      ...appropriateCheckpoints.map((checkpoint) => ({
        label: checkpoint.title,
        value: checkpoint.id,
      }))
    );
  }
  const modalTitle = editMode ? "Edit Checkpoint" : "Create A New Checkpoint";

  return (
    <FormModal
      title={modalTitle}
      handleSubmit={handleSubmit(onSubmit)}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      submitButtonDisabled={formValues.title === "" || formValues.referencePoint === ""}>
      <Flex width={"100%"} flexDirection={"column"} alignItems={"start"} gap={4}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormLabel>Checkpoint Name</FormLabel>
                <Input {...field} placeholder="e.g Achieve Milestone A" />
              </FormControl>
            );
          }}
        />
        <Controller
          name="referencePoint"
          control={control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormLabel m={0}>Dependent On</FormLabel>
                <FormHelperText mt={2} mb={3}>
                  Select the event that must occur before this checkpoint can be initiated.
                </FormHelperText>
                <Select
                  {...field}
                  placeholder="Select a dependency"
                  options={availableReferencePoints}
                />
              </FormControl>
            );
          }}
        />
        <FormControl>
          <VStack width={"100%"} gap={0}>
            <FormLabel w={"100%"} m={0}>
              Lead Time
            </FormLabel>
            <FormHelperText w={"100%"} mt={2} mb={3}>
              How long after your selected dependency do you anticipate that this checkpoint will be
              completed?
            </FormHelperText>
            <HStack w={"100%"} spacing={4}>
              <Box w={"100%"} flex={1}>
                <Controller
                  name="offsetValue"
                  control={control}
                  render={({ field }) => {
                    return <Input {...field} type="number" placeholder="0" min={0} />;
                  }}
                />
              </Box>
              <Box w={"100%"} flex={1}>
                <Controller
                  name="offsetPeriod"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        options={[
                          { label: "Days", value: "days" },
                          { label: "Weeks", value: "weeks" },
                          { label: "Months", value: "months" },
                        ]}
                      />
                    );
                  }}
                />
              </Box>
            </HStack>
          </VStack>
        </FormControl>
      </Flex>
    </FormModal>
  );
};
