import { ArrowForwardIcon, CheckCircleIcon, CheckIcon, NotAllowedIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Circle,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditableDate } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { FiTruck } from "react-icons/fi";
import { PiMoney } from "react-icons/pi";
import {
  PurchaseShowData,
  useGetPurchaseFinancials,
  useGetPurchaseLineItems,
  useUpdatePurchase,
} from "../../../../api/purchase";
import { UserLoggedInData } from "../../../../api/user";

type OrderTrackingProgressProps = {
  purchase: PurchaseShowData;
  isPurchasingAdmin?: boolean;
  onSuccessCallback: (id?: number) => () => Promise<void>;
  currentUser: UserLoggedInData;
};

type Step = {
  title: { completed: string; pending: string; unnecessary?: string; needsInformation?: string };
  icon: (any) => JSX.Element;
  status: "ordered" | "paid" | "delivered";
  disabled: boolean;
  completed: boolean;
  unnecessary: boolean;
  needsInformation: boolean;
};
export const OrderTrackingProgress = ({
  isPurchasingAdmin,
  purchase,
  onSuccessCallback,
}: OrderTrackingProgressProps) => {
  const lineItemsQuery = useGetPurchaseLineItems(purchase.id);
  const purchaseFinancials = useGetPurchaseFinancials(purchase.id);

  const { mutate: updatePurchase, isLoading } = useUpdatePurchase(onSuccessCallback);
  const [loadingField, setLoadingField] = useState<string | null>(null);

  const missingPayment = purchase.line_items_sum.subtract(purchase.purchase_payments_paid);
  const hasLineItems = (lineItemsQuery.data && lineItemsQuery.data.length > 0) || false;
  const paymentNecessary = missingPayment.isPos();
  const refundNecessary = missingPayment.isNeg();
  const isPaid = purchase.is_fully_paid;
  const isOrdered = !!purchase.ordered_at;
  const hasPayments = purchaseFinancials.data?.purchase_payments.length !== 0;
  const lineItemsAreZero = purchase.line_items_sum.isZero();

  const steps: Step[] = [
    {
      title: { completed: "Ordered", pending: "Mark as Ordered" },
      icon: CheckIcon,
      status: "ordered",
      disabled: !hasLineItems || (!isPurchasingAdmin && isPaid) || purchase.is_deleted,
      completed: isOrdered,
      unnecessary: false,
      needsInformation: false,
    },
    {
      title: {
        completed: "Paid",
        pending: refundNecessary ? "Awaiting Refund" : "Awaiting Payment",
        unnecessary: "No Payment Required",
        needsInformation: "Add Price Estimates for Items",
      },
      icon: PiMoney,
      status: "paid",
      disabled: true,
      completed: isPaid && isOrdered,
      unnecessary:
        !paymentNecessary && !refundNecessary && purchase.purchase_payments_paid.isZero(),
      needsInformation: !hasPayments && lineItemsAreZero,
    },
    {
      title: { completed: "Delivered", pending: "Mark as Delivered" },
      icon: FiTruck,
      status: "delivered",
      disabled: !purchase.ordered_at,
      completed: !!purchase.delivered_at,
      unnecessary: false,
      needsInformation: false,
    },
  ];

  const handleStepUpdate = (step: Step) => {
    if (step.disabled) return;
    switch (step.status) {
      case "ordered":
        if (!isPurchasingAdmin) break;
        toggleOrdered(purchase);
        break;
      case "delivered":
        toggleDelivered(purchase);
        break;
      default:
        break;
    }
  };

  const toggleDelivered = (purchase: PurchaseShowData) => {
    setLoadingField("delivered");
    if (purchase.delivered_at) {
      updatePurchase(
        { id: purchase.id, delivered_at: null },
        { onSuccess: () => setLoadingField(null) }
      );
    } else {
      updatePurchase(
        { id: purchase.id, delivered_at: DateTime.now().toISO() },
        { onSuccess: () => setLoadingField(null) }
      );
    }
  };

  const toggleOrdered = (purchase: PurchaseShowData) => {
    setLoadingField("ordered");
    if (purchase.ordered_at) {
      updatePurchase(
        {
          id: purchase.id,
          ordered_at: null,
        },
        { onSuccess: () => setLoadingField(null) }
      );
    } else {
      updatePurchase(
        {
          id: purchase.id,
          ordered_at: DateTime.now().toISO(),
        },
        { onSuccess: () => setLoadingField(null) }
      );
    }
  };

  const stepComponents = steps.map((step, index) => (
    <Stack
      direction={{ base: "column", md: "row" }}
      alignItems={{ base: "start", md: "center" }}
      width="full"
      justifyContent={{ base: "normal", md: "space-between" }}
      spacing={2}>
      <ProgressStep
        step={step}
        onClick={() => handleStepUpdate(step)}
        isLoading={isLoading}
        loadingField={loadingField}
      />
      {index < steps.length - 1 && (
        <Box
          display={{ base: "none", md: "flex" }}
          flex="1"
          height="2px"
          bg={useColorModeValue("gray.400", "gray.500")}
        />
      )}
    </Stack>
  ));

  return (
    <VStack
      width="full"
      align="start"
      spacing={4}
      borderRadius="md"
      bg={useColorModeValue("gray.50", "gray.700")}>
      <Flex
        justify="space-between"
        alignContent={{ base: "start", md: "center" }}
        direction={{ base: "column", md: "row" }}
        width="100%"
        p={4}>
        <Flex
          direction={{ base: "row", md: "column" }}
          justifyContent={{ base: "space-between" }}
          alignContent={{ base: "start" }}
          height={"56px"}>
          <Text fontSize="xs" mr={{ base: 4, md: 0 }}>
            Estimated Delivery
          </Text>
          <Text fontWeight="semibold">
            <EditableDate
              disabled={!isPurchasingAdmin || purchase.is_deleted}
              defaultValue={purchase.estimated_delivery_date || ""}
              onSubmit={(value) => {
                if (value) {
                  updatePurchase({ id: purchase.id, estimated_delivery_date: value });
                }
              }}
            />
          </Text>
        </Flex>
        <Flex
          width={{ base: "100%", md: "80%" }}
          justify={{ base: "flex-start", md: "space-between" }}
          align={{ base: "start", md: "center" }}
          direction={{ base: "column", md: "row" }}
          p={{ base: 0, md: 0 }}
          gap={5}
          overflow="wrap">
          {stepComponents}
        </Flex>
      </Flex>
    </VStack>
  );
};

type ProgressStepProps = {
  step: Step;
  onClick: () => void;
  isLoading?: boolean;
  loadingField?: string | null;
};

const getTitle = (step: Step): string => {
  if (step.completed) {
    return step.title.completed;
  } else if (step.needsInformation) {
    return step.title.needsInformation || "Needs Information";
  } else if (step.unnecessary) {
    return step.title.unnecessary || "Not Needed";
  } else {
    return step.title.pending;
  }
};

const getIcon = (step: Step): ((any) => JSX.Element) => {
  if (step.completed) {
    return CheckCircleIcon;
  } else if (step.needsInformation) {
    return NotAllowedIcon;
  } else if (step.unnecessary) {
    return ArrowForwardIcon;
  } else {
    return step.icon;
  }
};

const getIconColor = (step: Step): string => {
  if (step.completed) {
    return "teal.500";
  } else if (step.needsInformation) {
    return "yellow.500";
  } else if (step.unnecessary) {
    return "teal.500";
  } else {
    return "gray.400";
  }
};

const getTextColor = (step: Step): string => {
  if (step.completed) {
    return "teal.500";
  } else if (step.needsInformation) {
    return "yellow.500";
  } else if (step.unnecessary) {
    return "teal.500";
  } else {
    return "gray.400";
  }
};

// Update to ProgressStep component
export const ProgressStep = ({ step, onClick, isLoading, loadingField }: ProgressStepProps) => {
  const IconComponent = getIcon(step);
  const iconColor = getIconColor(step);

  if (!step.disabled) {
    return (
      <Button
        variant="ghost"
        onClick={onClick}
        size="lg"
        gap={2}
        p={1}
        height="56px"
        isLoading={isLoading && loadingField === step.status}>
        <Circle
          display="inline-flex"
          size={6}
          alignItems="center"
          justifyContent="center"
          border="1px"
          borderColor={step.completed ? "teal.500" : iconColor}>
          <Icon
            as={IconComponent}
            color={step.completed ? "teal.500" : iconColor}
            boxSize={step.completed ? 6 : 4}
          />
        </Circle>
        <Text fontSize="sm" fontWeight="medium" textColor={step.completed ? "teal.500" : "auto"}>
          {getTitle(step)}
        </Text>
      </Button>
    );
  } else {
    return (
      <HStack spacing={2} height="56px">
        <Circle
          display="inline-flex"
          size={6}
          alignItems="center"
          justifyContent="center"
          border="1px"
          borderColor={step.completed ? "teal.500" : iconColor}>
          <Icon
            as={IconComponent}
            color={step.completed ? "teal.500" : iconColor}
            boxSize={step.completed ? 6 : 4}
          />
        </Circle>
        <Text fontSize="sm" fontWeight="medium" textColor={getTextColor(step)}>
          {getTitle(step)}
        </Text>
      </HStack>
    );
  }
};
