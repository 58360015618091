import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  useDisclosure,
  Text,
  VStack,
  Box,
  Heading,
} from "@chakra-ui/react";
import { useUploadFile } from "../../../../api/blob_files";
import { DateTime } from "luxon";
import _, { DebouncedFunc } from "lodash";
import { UseMutateFunction } from "react-query";
import {
  PurchasePaymentShowData,
  PurchasePaymentUpdateParams,
} from "../../../../api/purchase_payment";

type PurchasePaymentConfirmationModalProps = {
  updatePurchasePayment: DebouncedFunc<
    UseMutateFunction<PurchasePaymentShowData, unknown, PurchasePaymentUpdateParams>
  >;
  setSession?: React.Dispatch<React.SetStateAction<number>>;
  uploadFileOnSuccess: (id?: number | undefined) => () => Promise<void>;
  isLoadingUpdatePayment?: boolean;
  payment: PurchasePaymentShowData;
  isDisabled: boolean;
};

export const PurchasePaymentConfirmationModal = ({
  updatePurchasePayment,
  uploadFileOnSuccess,
  isLoadingUpdatePayment,
  payment,
  isDisabled,
  setSession,
}: PurchasePaymentConfirmationModalProps) => {
  const { mutate: uploadFile, isLoading: isLoadingUploadFile } = useUploadFile(uploadFileOnSuccess);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [encodedFiles, setEncodedFiles] = useState<
    Promise<{
      name: string;
      file: string;
    }>[]
  >([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
      setEncodedFiles(promises);
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        colorScheme="teal"
        isDisabled={isDisabled}
        onClick={onOpen}
        isLoading={isLoadingUpdatePayment || isLoadingUploadFile}>
        Pay
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Make a Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start">
              <Text>
                Are you sure you want to mark this as paid? This will not actually move money:
                payment must be remitted separately.
              </Text>

              <Heading size="sm">Receipt (optional)</Heading>
              <Box p={2} w="100%" border="1px" borderColor="chakra-border-color" borderRadius="md">
                <input type="file" multiple={true} onChange={handleFileChange} />
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={isLoadingUpdatePayment || isLoadingUploadFile}
                colorScheme="teal"
                onClick={() => {
                  updatePurchasePayment(
                    {
                      id: payment.id,
                      status: "paid",
                      paid_at: DateTime.now(),
                    },
                    {
                      onSuccess: () => {
                        if (encodedFiles.length > 0) {
                          Promise.all(encodedFiles)
                            .then((encodedFileObjects) => {
                              encodedFileObjects.forEach((file) => {
                                uploadFile({
                                  content_data_uri: file.file,
                                  filename: file.name,
                                  fileable_type: "PurchasePayment",
                                  fileable_id: payment.id,
                                  fileable_field: "uploaded_files",
                                });
                              });
                            })
                            .catch((error) => console.error(error));
                        }
                        onClose();
                        setSession && setSession((session) => ++session);
                      },
                    }
                  );
                }}>
                Submit
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
