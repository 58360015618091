import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInventoryOptions } from "../../../../api/options";
import { useCreateInventoryBomComponent } from "../../../../api/inventory_bom_components";
import { useGetInventory } from "../../../../api/inventory";
import { captureConsoleIntegration } from "@sentry/react";

type FormValuesNewComponentModalType = {
  inventory_id: number | null;
  quantity: number;
  is_consumable: boolean;
};

type NewComponentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  bomId: number;
  selectedInventoryIds?: number[];
};

export const NewComponentModal = ({
  isOpen,
  onClose,
  bomId,
  selectedInventoryIds,
}: NewComponentModalProps) => {
  const { setValue, control, watch, reset, handleSubmit } =
    useForm<FormValuesNewComponentModalType>({
      defaultValues: { inventory_id: null, quantity: 0, is_consumable: false },
    });

  const inventoryOptions = useInventoryOptions(selectedInventoryIds);
  const { mutateAsync: createInventoryBomComponent, isLoading: isCreateLoading } =
    useCreateInventoryBomComponent();
  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const isSubmitDisabled = !formValues.inventory_id || !formValues.quantity || isCreateLoading;

  const { data: inventory } = useGetInventory(formValues.inventory_id);

  useEffect(() => {
    if (formValues.inventory_id && inventory) {
      setValue("is_consumable", inventory.is_consumable || false);
    }
  }, [formValues.inventory_id, inventory, setValue]);

  return (
    <FormModal
      title="Add Component"
      submitButtonColorSchema="teal"
      submitButtonTitle="Submit"
      size="2xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
      handleSubmit={() =>
        formValues.inventory_id &&
        createInventoryBomComponent({
          inventory_bom_id: bomId,
          inventory_id: formValues.inventory_id,
          quantity: formValues.quantity,
        })
          .then(() => {
            handleClose();
          })
          .catch((error) => {
            console.error(error);
          })
      }
      submitButtonDisabled={isSubmitDisabled}>
      <FormControl isRequired>
        <FormLabel>Specify the inventory item you would like to add to the BOM.</FormLabel>
        <Flex direction="column" gap={4}>
          <Controller
            name="inventory_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Inventory Item</FormLabel>
                <Select {...field} options={inventoryOptions} />
              </FormControl>
            )}
          />
          <Controller
            name={"quantity"}
            control={control}
            rules={{ required: true, min: 1, max: 1000000 }}
            render={({ field }) => {
              return (
                <FormControl isRequired>
                  <FormLabel>Quantity of Item</FormLabel>
                  <HStack alignItems={"center"}>
                    <NumberInput
                      {...field}
                      value={field.value}
                      allowMouseWheel={false}
                      max={1000000}
                      min={0}
                      step={formValues.is_consumable ? 0.01 : 1}
                      precision={formValues.is_consumable ? 2 : 0}
                      clampValueOnBlur={true}
                      width={"max-content"}
                      onSubmit={(value) => {
                        setValue("quantity", Number(value));
                      }}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </HStack>
                </FormControl>
              );
            }}
          />
        </Flex>
      </FormControl>
    </FormModal>
  );
};
