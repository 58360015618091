import { AddIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { usePayTypeOptions } from "../../../../api/options";
import {
  hoursSchema,
  useCreateUserCompensation,
  useGetUserCompPublicKeys,
  userCompensationMessageSchema,
} from "../../../../api/user_compensation";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import * as Errors from "../../../../helpers/errors";
import { KeyringZodHelper } from "../../../../helpers/KeyringZodHelper";
import { Money } from "../../../../helpers/Money";
import { required } from "../../../../helpers/validation";
import { MoneyInput } from "../../../MoneyInput";
import SelectFormControl from "../../../shared/form/SelectFormControl";

type NewCompensationModalProps = {
  user_id: number;
  isOpen: boolean;
  onClose: () => void;
};

type FormData = {
  pay_type: "salary_v2" | "hourly_v2";
  yearly_salary: Money;
  hourly_rate: Money;
  min_hours_per_week: string;
  max_hours_per_week: string;
};

const NewCompensationModal: React.FC<NewCompensationModalProps> = ({
  user_id,
  isOpen,
  onClose,
}) => {
  const currency = useCurrency();
  const defaultValues: FormData = {
    pay_type: "salary_v2",
    yearly_salary: Money.zero(currency),
    hourly_rate: Money.zero(currency),
    min_hours_per_week: "0",
    max_hours_per_week: "0",
  };
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({ mode: "all", defaultValues });
  const payTypeOptions = usePayTypeOptions();
  const userKeysQuery = useGetUserCompPublicKeys();
  const toast = useToast();
  const { mutateAsync: createUserCompenstation, isLoading, isError } = useCreateUserCompensation();

  const onSubmit = handleSubmit((data) => {
    if (!userKeysQuery.data) {
      toast({
        title: "Error",
        description: "No public keys",
      });
    }
    const keyringHelper = new KeyringZodHelper(userCompensationMessageSchema);
    const encryptedMessage = keyringHelper.encrypt(
      data.pay_type === "salary_v2"
        ? {
            data: {
              payType: data.pay_type,
              yearlySalary: data.yearly_salary,
            },
          }
        : {
            data: {
              payType: data.pay_type,
              hourlyRate: data.hourly_rate,
              minHoursPerWeek: parseInt(data.min_hours_per_week),
              maxHoursPerWeek: parseInt(data.max_hours_per_week),
            },
          },
      userKeysQuery.data?.user_public_keys.map((key) => key.public_key)
    );

    createUserCompenstation({
      userId: user_id,
      keyringMessageContent: encryptedMessage,
    }).then((data) => {
      onClose();
    });
  });

  return (
    <FormModal
      title="Add Compensation"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      submitButtonTitle="Create"
      submitButtonDisabled={isLoading}
      isLoading={isLoading}
      submitButtonColorSchema="teal"
      handleSubmit={onSubmit}
      errorMessage={isError ? Errors.generic : ""}>
      <Controller
        name="pay_type"
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <SelectFormControl
            label="Pay Type"
            options={payTypeOptions}
            error={errors.pay_type?.message}
            isRequired
            {...field}
          />
        )}
      />
      <NewCompensationFormFields payType={watch().pay_type} control={control} />
    </FormModal>
  );
};

export const NewCompensationFormFields: React.FC<{
  payType: "salary_v2" | "hourly_v2";
  control: Control<FormData>;
}> = ({ payType, control }) => {
  const currency = useCurrency();
  if (payType === "salary_v2") {
    return (
      <>
        <Controller
          name={"yearly_salary"}
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Yearly Salary</FormLabel>
              <MoneyInput isRequired {...field} />
            </FormControl>
          )}
        />
      </>
    );
  } else {
    return (
      <>
        <Controller
          name={"hourly_rate"}
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Hourly Rate</FormLabel>
              <MoneyInput isRequired {...field} />
            </FormControl>
          )}
        />
        <Controller
          name={"min_hours_per_week"}
          control={control}
          rules={{
            validate: (value, formValues) => {
              return !!hoursSchema.safeParse(value);
            },
          }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Min Hours Per Week</FormLabel>
              <NumberInput {...field} allowMouseWheel={false}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          )}
        />
        <Controller
          name={"max_hours_per_week"}
          control={control}
          rules={{
            validate: (value, formValues) => {
              return !!hoursSchema.safeParse(value);
            },
          }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Max Hours Per Week</FormLabel>
              <NumberInput {...field} allowMouseWheel={false}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          )}
        />
      </>
    );
  }
};

export const NewCompensationButton: React.FC<{ user_id: number }> = ({ user_id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton aria-label="add compensation" icon={<AddIcon />} onClick={onOpen} size="sm" />

      {isOpen ? <NewCompensationModal user_id={user_id} isOpen={isOpen} onClose={onClose} /> : null}
    </>
  );
};
