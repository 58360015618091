import React, { useState, useEffect, useMemo } from "react";
import {
  VStack,
  Box,
  HStack,
  Text,
  StatGroup,
  Stat,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
  OrderedList,
  ListItem,
  Link,
  Heading,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  StatLabel,
  Flex,
  Divider,
  StatNumber,
  Stack,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
} from "@chakra-ui/react";
import {
  SplitPage,
  AttributesTable,
  Select,
  ConfirmationButton,
  EditableText,
} from "@sciencecorp/helix-components";
import { useGetTrainingMaterials } from "../../../api/trainings";
import {
  useGetTrainingSession,
  useGetAssociatedUserTrainingSessions,
  useDeleteTrainingSession,
  useDeleteOptionalUserTrainingSessions,
  useUpdateTrainingSession,
  invalidateTrainingSession,
} from "../../../api/training_sessions";
import {
  UserTrainingSessionData,
  useNewUserTrainingSession,
  useNewUserTrainingSessionForAllEmployees,
  useUpdateAllUserTrainingSessions,
} from "../../../api/user_training_sessions";
import { useUserOptions } from "../../../api/options";
import { UserTrainingSessionTable } from "./components/UserTrainingSessionTable";
import { FacilitatorCard } from "../util";
import { DateTime } from "luxon";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { meanBy } from "lodash";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { trainingSessionInPast } from "../util";
import { ChevronRightIcon } from "@chakra-ui/icons";

export const TrainingSessionPage = () => {
  const { id, sessionId } = useParams();

  if (!id || !sessionId) return null;
  const { data: trainingSession, isLoading, isError } = useGetTrainingSession(+sessionId);
  const { data: userTrainingSessions } = useGetAssociatedUserTrainingSessions(+sessionId);
  const { data: trainingMaterials } = useGetTrainingMaterials(+id);
  const { mutate: deleteTrainingSession } = useDeleteTrainingSession(+id);
  const { mutate: updateTrainingSession } = useUpdateTrainingSession(() =>
    invalidateTrainingSession(+sessionId)
  );
  const { mutate: updateAllUserTrainingSessions } = useUpdateAllUserTrainingSessions();

  const [workingUserTrainingSessions, setWorkingUserTrainingSessions] = useState<
    UserTrainingSessionData[]
  >([]);
  const currentUser = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUser, "credentials_admin");

  const navigate = useNavigate();

  useEffect(() => {
    if (userTrainingSessions) setWorkingUserTrainingSessions([...userTrainingSessions.attendees]);
  }, [userTrainingSessions]);

  const handleTestScoreChange = (id: number, score: number) => {
    const updatedAttendees = [...workingUserTrainingSessions];
    const index = updatedAttendees.findIndex((attendee) => attendee.id === id);
    updatedAttendees[index].test_score = score;
    setWorkingUserTrainingSessions(updatedAttendees);
  };
  const handleAttendanceChange = (id: number, attended: boolean) => {
    const updatedAttendees = [...workingUserTrainingSessions];
    const index = workingUserTrainingSessions.findIndex((attendee) => attendee.id === id);
    const status = attended ? "attended" : "skipped";
    updatedAttendees[index].status = status;
    setWorkingUserTrainingSessions(updatedAttendees);
  };

  const averageScore = () => {
    const attendingUsers = workingUserTrainingSessions.filter(
      (ele) => ele.status === "attended" || ele.status === "completed"
    );
    return (meanBy(attendingUsers, "test_score") || 0).toFixed();
  };

  const pastTrainingSessionDate = useMemo(
    () => (trainingSession?.scheduled_date || DateTime.now()) < DateTime.now(),
    [trainingSession?.scheduled_date]
  );

  if (isLoading) return <Spinner />;
  if (isError) return <Text>Error loading training session.</Text>;

  return (
    <>
      {pastTrainingSessionDate && !trainingSession.started_at && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <VStack align="start" spacing={0}>
            <AlertTitle>Reminder</AlertTitle>
            <AlertDescription>
              You are consolidating attendance data for a past training session that wasn't recorded
              in Helix. Please add all attendees before inputing test score.
            </AlertDescription>
          </VStack>
        </Alert>
      )}
      <Breadcrumb color="teal.500" separator={<ChevronRightIcon />} mb={6}>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to="/credentials/trainings">
            Trainings
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={`/trainings/${id}`}>
            {trainingSession.training.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink color={useColorModeValue("gray.700", "gray.300")}>
            Training Session
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <SplitPage
        sidebar={
          <VStack width="100%" align="start" spacing={3}>
            <Box
              width="100%"
              p={2}
              border="1px"
              borderRadius="md"
              borderColor="chakra-border-color"
              bg={useColorModeValue("gray.50", "gray.700")}>
              <AttributesTable
                title="Session Info"
                attributes={[
                  {
                    label: "Date",
                    value: trainingSession.scheduled_date.toFormat("LLL dd yyyy"),
                  },
                  {
                    label: "Started At",
                    value: trainingSession.started_at
                      ? trainingSession.started_at?.toFormat("hh:mm a")
                      : "-",
                  },
                  {
                    label: "Cutoff Score",
                    value: trainingSession.cutoff_score ? `${trainingSession.cutoff_score}%` : "-",
                  },

                  {
                    label: "Max Attendees",
                    value: trainingSession.max_people ? trainingSession.max_people : "-",
                  },
                  {
                    label: "Notes",
                    value: (
                      <EditableText
                        multiline
                        disabled={
                          trainingSession.facilitator.id !== currentUser?.data?.id &&
                          !isCredentialsAdmin
                        }
                        defaultValue={trainingSession.notes}
                        onSubmit={(value) => {
                          if (value) updateTrainingSession({ id: +sessionId, notes: value });
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Box>
            <VStack
              align="start"
              width="100%"
              p={4}
              border="1px"
              borderRadius="md"
              borderColor="chakra-border-color"
              bg={useColorModeValue("gray.50", "gray.700")}>
              <Text fontSize="xs" fontWeight="bold" textTransform="uppercase">
                Facilitator
              </Text>
              <FacilitatorCard facilitator={trainingSession?.facilitator} />
            </VStack>
            <VStack
              width="100%"
              align="start"
              p={4}
              border="1px"
              borderRadius="md"
              borderColor="chakra-border-color"
              bg={useColorModeValue("gray.50", "gray.700")}>
              <Text
                fontSize="xs"
                color={useColorModeValue("gray.600", "gray.300")}
                fontWeight="bold"
                textTransform="uppercase">
                Training Materials
              </Text>
              <OrderedList pl={4} width="100%">
                {trainingMaterials?.map((material) => (
                  <>
                    <ListItem>
                      <Link color="teal.500" href={material.link} isExternal>
                        {material.title}
                      </Link>
                    </ListItem>
                  </>
                ))}
              </OrderedList>
            </VStack>
          </VStack>
        }
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        main={
          !trainingSession?.started_at ? (
            <VStack maxW="3xl" align="start">
              <HStack width="100%" justify="space-between">
                <Heading size="md">Manage Attendees</Heading>
                {isCredentialsAdmin && trainingSessionInPast(trainingSession.scheduled_date) ? (
                  <ButtonGroup>
                    <ConfirmationButton
                      width="100%"
                      colorScheme="red"
                      buttonVariant="outline"
                      label="Delete Session"
                      confirmationButtonLabel="Delete Session"
                      variant="Button"
                      confirmationHeader="Delete Session"
                      children="Are you sure you want to delete this session? This action cannot be undone."
                      onConfirm={() => {
                        deleteTrainingSession(+sessionId, {
                          onSuccess: () => navigate(`/trainings/${id}`),
                        });
                      }}
                    />
                    <ConfirmationButton
                      width="100%"
                      colorScheme="teal"
                      label="Submit Attendance"
                      confirmationButtonLabel="Submit Attendance"
                      variant="Button"
                      confirmationHeader="Submit Training Session Attendnace"
                      children="Please ensure that you have entered accurate information for all attendees before you end the training session."
                      onConfirm={() => {
                        updateAllUserTrainingSessions(workingUserTrainingSessions);
                        updateTrainingSession(
                          { id: +sessionId },
                          { onSuccess: () => navigate(`/trainings/${id}`) }
                        );
                      }}
                    />
                  </ButtonGroup>
                ) : isCredentialsAdmin ||
                  currentUser?.data?.id === trainingSession?.facilitator.id ? (
                  <ButtonGroup>
                    {!trainingSession?.started_at && (
                      <ConfirmationButton
                        width="100%"
                        colorScheme="red"
                        buttonVariant="outline"
                        label="Delete Session"
                        confirmationButtonLabel="Delete Session"
                        variant="Button"
                        confirmationHeader="Delete Session"
                        children="Are you sure you want to delete this session? This action cannot be undone."
                        onConfirm={() => {
                          deleteTrainingSession(+sessionId, {
                            onSuccess: () => navigate(`/trainings/${id}`),
                          });
                        }}
                      />
                    )}
                    <ConfirmationButton
                      width="100%"
                      colorScheme="teal"
                      label="Start Session"
                      confirmationButtonLabel="Start Session"
                      variant="Button"
                      confirmationHeader="Before You Start"
                      children="After starting the training session, please mark all present employees by toggling the attendance column. To mark someone as absent, leave the toggle in the attendance column turned off."
                      onConfirm={() => {
                        updateTrainingSession({
                          id: +sessionId,
                          started_at: DateTime.now(),
                        });
                      }}
                    />
                  </ButtonGroup>
                ) : null}
              </HStack>
              {trainingSessionInPast(trainingSession.scheduled_date) ? (
                <VStack width="100%">
                  <AddUserToTrainingSession
                    pastTrainingSessionDate={pastTrainingSessionDate}
                    userTrainingSessions={userTrainingSessions}
                    trainingSessionId={+sessionId}
                  />
                  {trainingSession && (
                    <UserTrainingSessionTable
                      userTrainingSessions={userTrainingSessions?.attendees || []}
                      trainingSession={trainingSession}
                      handleTestScoreChange={handleTestScoreChange}
                    />
                  )}
                </VStack>
              ) : (
                <Tabs width="100%" colorScheme="teal">
                  <TabList>
                    <Tab>Attendees ({userTrainingSessions?.attendee_count})</Tab>
                    <Tab>Skipped ({userTrainingSessions?.skipped_count})</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <AddUserToTrainingSession
                        pastTrainingSessionDate={pastTrainingSessionDate}
                        userTrainingSessions={userTrainingSessions}
                        trainingSessionId={+sessionId}
                      />
                      {trainingSession && (
                        <UserTrainingSessionTable
                          userTrainingSessions={userTrainingSessions?.attendees || []}
                          trainingSession={trainingSession}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {trainingSession && (
                        <UserTrainingSessionTable
                          userTrainingSessions={userTrainingSessions?.skipped_attendees || []}
                          trainingSession={trainingSession}
                        />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </VStack>
          ) : (
            <Stack
              width="100%"
              spacing={6}
              direction={{ base: "column", lg: "row" }}
              align={{ base: "", lg: "start" }}>
              <Flex flexGrow="2">
                <UserTrainingSessionTable
                  userTrainingSessions={workingUserTrainingSessions}
                  trainingSession={trainingSession}
                  skippedUserTrainingSessions={userTrainingSessions?.skipped_attendees || []}
                  handleTestScoreChange={handleTestScoreChange}
                  handleAttendanceChange={handleAttendanceChange}
                />
              </Flex>
              <VStack
                flexGrow="1"
                align="start"
                p={4}
                border="1px"
                borderRadius="md"
                borderColor="chakra-border-color">
                <StatGroup width="100%" flexDir={{ base: "row", lg: "column", xl: "row" }}>
                  <Stat>
                    <StatLabel>Attendees</StatLabel>
                    <StatNumber>
                      {
                        workingUserTrainingSessions.filter(
                          (ele) => ele.status === "attended" || ele.status === "completed"
                        ).length
                      }
                      /{workingUserTrainingSessions.length}
                    </StatNumber>
                  </Stat>
                  {trainingSession.cutoff_score ? (
                    <Stat>
                      <StatLabel>Average Score</StatLabel>
                      <StatNumber>{averageScore()}%</StatNumber>
                    </Stat>
                  ) : null}
                </StatGroup>
                <Divider />
                <ConfirmationButton
                  width="100%"
                  colorScheme="teal"
                  label="End Session"
                  confirmationButtonLabel="End Session Now"
                  variant="Button"
                  confirmationHeader="End Session"
                  children="Please ensure that you have entered accurate information for all attendees before you end the training session."
                  onConfirm={() => {
                    updateAllUserTrainingSessions(workingUserTrainingSessions);
                    updateTrainingSession(
                      { id: +sessionId, ended_at: DateTime.now() },
                      { onSuccess: () => navigate(`/trainings/${id}`) }
                    );
                  }}
                />
              </VStack>
            </Stack>
          )
        }
      />
    </>
  );
};

const AddUserToTrainingSession = ({
  userTrainingSessions,
  trainingSessionId,
  pastTrainingSessionDate,
}) => {
  const userOptions = useUserOptions(userTrainingSessions?.user_ids || []);
  const { mutate: newUserTrainingSession } = useNewUserTrainingSession(() =>
    invalidateTrainingSession(trainingSessionId)
  );
  const { mutate: newUserTrainingSesssionAllEmployees } =
    useNewUserTrainingSessionForAllEmployees();
  const { mutate: deleteOptionalUserTrainingSessions } =
    useDeleteOptionalUserTrainingSessions(trainingSessionId);
  return (
    <HStack mb={4} width="100%">
      <Box width="60%">
        <Select
          placeholder="Add an employee"
          options={userOptions}
          onChange={(value) => {
            const status = pastTrainingSessionDate ? "attended" : "registered";
            newUserTrainingSession({
              user_id: +value,
              training_session_id: trainingSessionId,
              status: status,
            });
          }}
        />
      </Box>
      <Button
        variant="ghost"
        size="sm"
        colorScheme="teal"
        onClick={() => {
          const user_ids = userOptions.map((user) => +user.value);
          newUserTrainingSesssionAllEmployees({
            user_ids,
            training_session_id: trainingSessionId,
          });
        }}>
        Add all Science employees
      </Button>
      <Button
        variant="ghost"
        size="sm"
        colorScheme="red"
        onClick={() => deleteOptionalUserTrainingSessions(trainingSessionId)}>
        Clear All
      </Button>
    </HStack>
  );
};
