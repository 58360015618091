import { EditIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  useColorModeValue,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Box,
  Text,
} from "@chakra-ui/react";
import { RichTextEditor } from "@sciencecorp/helix-components";
import _ from "lodash";
import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useUpdateCapitalEquipmentLog } from "../../api/capital_equipment_log";
import { LogEditHistory } from "../CapitalEquipments/components/MaintenanceTab/LogEditHistory";
import { getValueAtPath, RenderTemplateValue } from "./TimelineEventText";
import { TimelineEventData } from "../../api/timeline_events";

const emptyRichText = JSON.stringify({
  root: {
    type: "root",
    format: "",
    indent: 0,
    version: 1,
    children: [
      {
        type: "paragraph",
        format: "",
        indent: 0,
        version: 1,
        children: [
          {
            mode: "normal",
            text: "",
            type: "text",
            style: "",
            detail: 0,
            format: 0,
            version: 1,
          },
        ],
        direction: "ltr",
      },
    ],
    direction: "ltr",
  },
});

export const RenderLogTimeline = ({
  event,
  onComment,
}: {
  event: TimelineEventData;
  onComment: (action: "created" | "updated" | "deleted") => void;
}) => {
  let template = event.timeline_template;
  const rawContent = getValueAtPath<string | object>(event, _.last(template)?.valuePath!);
  const content = typeof rawContent === "object" ? JSON.stringify(rawContent, null, 2) : rawContent;
  let templates = template ? template.slice(0, template?.length - 1) : [];
  const menuButtonBg = useColorModeValue("gray.400", "gray.700");
  const [edit, setEdit] = useState(false);
  const { mutateAsync: updateCapitalEquipmentLog, isLoading } = useUpdateCapitalEquipmentLog();

  const bgColor = useColorModeValue("gray.50", "gray.600");
  const {
    isOpen: isOpenEditHistory,
    onOpen: onOpenEditHistory,
    onClose: onCloseEditHistory,
  } = useDisclosure();

  return (
    <>
      <LogEditHistory
        isOpen={isOpenEditHistory}
        onClose={onCloseEditHistory}
        capitalEquipmentLogId={event.timelineable_id}
      />
      <Box width="100%" maxW="3xl">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
          bg={bgColor}
          p={2}
          mb={-2}
          borderTopRadius="lg"
          borderBottomRadius={content?.length ? "none" : "lg"}
          border="1px"
          borderColor="chakra-border-color"
          borderBottom={content?.length ? "0px" : ""}>
          <Text>
            {templates.map((templateValue, idx) => (
              <RenderTemplateValue
                key={`${event.id}-template-${idx}`}
                shouldCapitalize={false}
                event={event}
                avatarSize={"xs"}
                templateValue={templateValue}
                timelineableId={event.timelineable_id}
                timelineableType={event.timelineable_type}
              />
            ))}
          </Text>
          <Menu placement="bottom-end">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<BsThreeDots size={20} />}
              size="xs"
              color={menuButtonBg}
              variant="ghost"
            />
            <MenuList alignItems="center">
              <MenuItem
                icon={<EditIcon />}
                onClick={() => {
                  setEdit(true);
                }}>
                Edit comment
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>

        <RichTextEditor
          width="100%"
          editable={edit}
          defaultValue={content !== "{}" ? content : emptyRichText}
          onSave={(rich, plainText) => {
            return updateCapitalEquipmentLog({
              id: event.timelineable_id,
              notes: JSON.parse(rich) ?? "",
            })
              .then(() => {
                setEdit(false);
                onComment && onComment("updated");
                return true;
              })
              .catch((error) => {
                console.error(error);
                return false;
              });
          }}
        />

        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          cursor={"pointer"}
          onClick={onOpenEditHistory}>
          <Text p={2} fontSize="sm" colorScheme="gray">
            Last edited: {event.updated_at.toFormat("LLL dd, yyyy 'at' hh:mm a")}
          </Text>
          <ChevronRightIcon />
        </Box>
      </Box>
    </>
  );
};
