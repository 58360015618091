import React, { useEffect, useState } from "react";
import { FormModal, Option } from "@sciencecorp/helix-components";
import {
  Flex,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  HStack,
  Text,
  Tag,
  Stack,
  Box,
  useColorModeValue,
  Editable,
  IconButton,
  Divider,
  EditableInput,
  EditablePreview,
  Heading,
  useToast,
} from "@chakra-ui/react";
import {
  InventoryShowData,
  useInventoryLocationsWithInventoryOptions,
  useMoveInventoryStock,
} from "../../../../api/inventory";
import { BsArrowRightSquare } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import {
  LocationTreeKeyValue,
  LocationTreeOption,
  LocationTreeValue,
  useTreeSelectInventoryLocations,
} from "../../../../api/inventory_location";
import { findSelectedOption, TreeSelect } from "../../../shared/TreeSelect";
import { initial } from "lodash";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { LocationTreePreview } from "./RestockModalComponents/AddStockAndLocation";

type NewInventoryItemModalProps = {
  inventory: InventoryShowData;
  startingLocationId?: number;
};

type MoveStockFormValues = {
  starting_location: LocationTreeValue | null;
  ending_location: LocationTreeValue | null;
  quantity: number;
};

const moveStockDefaultValues = {
  starting_location: null,
  ending_location: null,
  quantity: 0,
};

export const MoveStockModal = ({ inventory, startingLocationId }: NewInventoryItemModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit, watch, setValue, reset } = useForm<MoveStockFormValues>({
    defaultValues: moveStockDefaultValues,
  });

  const { mutate: moveInventory, isLoading } = useMoveInventoryStock(inventory.id);
  const formValues = watch();
  const [startingLocationSearch, setStartingLocationSearch] = useState("");
  const [startingLocationPreview, setStartingLocationPreview] = useState<boolean>(false);
  const [pathToStartingLocation, setPathToStartingLocation] = useState<
    LocationTreeKeyValue[] | undefined
  >();
  const [endingLocationSearch, setEndingLocationSearch] = useState("");
  const [endingLocationPreview, setEndingLocationPreview] = useState<boolean>(false);
  const [pathToEndingLocation, setPathToEndingLocation] = useState<
    LocationTreeKeyValue[] | undefined
  >();

  const inventoryLocationsWithInventoryOptionsQuery = useInventoryLocationsWithInventoryOptions(
    inventory.id,
    startingLocationSearch
  );

  const treeSelectInventoryLocationsQuery = useTreeSelectInventoryLocations(
    endingLocationSearch,
    "Select Location"
  );

  const toast = useToast();

  useEffect(() => {
    if (startingLocationId && inventoryLocationsWithInventoryOptionsQuery.data) {
      const startingLocation = findSelectedOptionWithId(
        inventoryLocationsWithInventoryOptionsQuery.data,
        startingLocationId
      );
      setValue("starting_location", startingLocation);
    }
  }, [inventoryLocationsWithInventoryOptionsQuery?.data, startingLocationId, isOpen]);

  const handleClose = () => {
    setStartingLocationPreview(false);
    setEndingLocationPreview(false);
    setPathToStartingLocation(undefined);
    setPathToEndingLocation(undefined);
    reset();
    onClose();
  };

  const onSubmit = (data: MoveStockFormValues) => {
    if (data.starting_location && data.ending_location) {
      moveInventory(
        {
          id: inventory.id,
          starting_location_id: data.starting_location.id,
          ending_location_id: data.ending_location.id,
          quantity: data.quantity,
        },
        {
          onSuccess: () => {
            handleClose();
            toast({
              title: "Stock Moved",
              description: "Stock has been successfully moved.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          },
          onError: () => {
            toast({
              title: "Failed to move stock",
              description: "An error occurred while moving stock.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    }
  };

  return (
    <>
      <Button leftIcon={<BsArrowRightSquare />} onClick={onOpen} isDisabled={inventory.is_archived}>
        Move Stock
      </Button>

      <FormModal
        title="Move Stock"
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={handleClose}
        isLoading={isLoading}
        submitButtonDisabled={
          !formValues.starting_location ||
          !formValues.ending_location ||
          !formValues.quantity ||
          formValues.quantity > (formValues.starting_location?.quantity || 0) ||
          formValues.starting_location.id === formValues.ending_location.id
        }
        submitButtonColorSchema="teal"
        size="xl"
        handleSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" w="100%" gap={4}>
          <Controller
            name="starting_location"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Choose Location to Move From</FormLabel>
                {startingLocationPreview ? (
                  <LocationTreePreview
                    pathToLocation={pathToStartingLocation}
                    setShowPreview={setStartingLocationPreview}
                  />
                ) : (
                  <TreeSelect
                    options={inventoryLocationsWithInventoryOptionsQuery.data || []}
                    placeholder="Select Location"
                    isLoading={inventoryLocationsWithInventoryOptionsQuery.isLoading}
                    onSearchChanged={(value) => {
                      setStartingLocationSearch(value);
                    }}
                    defaultValue={field.value}
                    onChange={(value) => {
                      if (value) {
                        setValue("starting_location", value);
                        findPathToLocationAndShowPreview(
                          inventoryLocationsWithInventoryOptionsQuery?.data || [],
                          value,
                          setPathToStartingLocation,
                          setStartingLocationPreview
                        );
                      }
                    }}
                    renderOption={(option) => defaultOption(option)}
                  />
                )}
              </FormControl>
            )}
          />
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <FormControl w="100%" isRequired={inventory.is_consumable}>
                <Flex
                  justify="center"
                  align="center"
                  p={4}
                  direction="column"
                  gap={4}
                  bg={useColorModeValue("gray.50", "gray.600")}
                  borderRadius="sm">
                  <Flex align="center" gap={4} justify="space-between" minW={40}>
                    <IconButton
                      icon={<MinusIcon />}
                      aria-label="decrement"
                      colorScheme="red"
                      variant="outline"
                      borderRadius="full"
                      size="xs"
                      isDisabled={field.value <= 0}
                      onClick={() => field.onChange(field.value - 1)}
                    />
                    <Editable defaultValue={field.value.toString()} value={field.value.toString()}>
                      <Heading size="3xl">
                        <EditablePreview />
                      </Heading>
                      <Box>
                        <EditableInput
                          fontSize="4xl"
                          type="number"
                          w={32}
                          max={formValues.starting_location?.quantity || 0}
                          onInput={(e) => {
                            const value = e.currentTarget.value;
                            if (value && !isNaN(Number(value))) {
                              field.onChange(Number(value));
                            } else if (value === "") {
                              // Allow empty value temporarily
                              field.onChange("");
                            } else {
                              e.currentTarget.value = field.value.toString();
                            }
                          }}
                          onBlur={(e) => {
                            const value = e.target.value;
                            if (value === "" || isNaN(Number(value)) || Number(value) <= 0) {
                              field.onChange(1);
                              e.target.value = "1";
                            } else if (
                              Number(value) > (formValues.starting_location?.quantity || 0)
                            ) {
                              field.onChange(formValues.starting_location?.quantity || 0);
                              e.target.value = (
                                formValues.starting_location?.quantity || 0
                              ).toString();
                            }
                          }}
                        />
                      </Box>
                    </Editable>
                    <IconButton
                      icon={<AddIcon />}
                      aria-label="increment"
                      colorScheme="teal"
                      variant="outline"
                      isDisabled={
                        field.value >= 500 ||
                        field.value >= (formValues.starting_location?.quantity || 0)
                      }
                      borderRadius="full"
                      size="xs"
                      onClick={() => field.onChange(field.value + 1)}
                    />
                  </Flex>
                  <Divider />
                  <Text fontSize="sm" color={useColorModeValue("gray.600", "auto")}>
                    Available Quantity:{" "}
                    <Box as="span" fontWeight="semibold">
                      {formValues.starting_location?.quantity || 0}
                    </Box>
                  </Text>
                </Flex>
              </FormControl>
            )}
          />
          <Controller
            name="ending_location"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Choose Destination</FormLabel>
                {endingLocationPreview ? (
                  <LocationTreePreview
                    pathToLocation={pathToEndingLocation}
                    setShowPreview={setEndingLocationPreview}
                  />
                ) : (
                  <TreeSelect
                    options={treeSelectInventoryLocationsQuery.data || []}
                    placeholder="Select Location"
                    isLoading={treeSelectInventoryLocationsQuery.isLoading}
                    onSearchChanged={(value) => {
                      setEndingLocationSearch(value);
                    }}
                    defaultValue={null}
                    onChange={(value) => {
                      if (value) {
                        setValue("ending_location", value);
                        findPathToLocationAndShowPreview(
                          treeSelectInventoryLocationsQuery?.data || [],
                          value,
                          setPathToEndingLocation,
                          setEndingLocationPreview
                        );
                      }
                    }}
                    renderOption={(option) => defaultOption(option)}
                  />
                )}
              </FormControl>
            )}
          />
        </Flex>
      </FormModal>
    </>
  );
};

const defaultOption = (option: LocationTreeKeyValue) => {
  const fontWeight = option.value?.storage_item ? "semibold" : "normal";
  return (
    <HStack>
      <Text fontWeight={fontWeight}>{option.label}</Text>
      {option?.value?.capacity_for_storage && <Tag colorScheme="teal">In Stock</Tag>}
    </HStack>
  );
};

const findPathToLocationAndShowPreview = (
  data: LocationTreeOption[],
  location: LocationTreeValue,
  setPath: React.Dispatch<React.SetStateAction<LocationTreeKeyValue[] | undefined>>,
  setPreview: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const path = findSelectedOption(data, location);
  setPath(initial(path));
  setPreview(true);
};

const findSelectedOptionWithId = (options: LocationTreeOption[], id: number) => {
  for (const option of options) {
    if (option.value.id === id) {
      return {
        ...option.value,
        capacity_for_storage: false,
        storage_item: true,
      };
    }
    if (option.children) {
      const found = findSelectedOptionWithId(option.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
