import React, { useEffect, useState } from "react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import {
  Input,
  FormControl,
  FormLabel,
  Flex,
  Box,
  HStack,
  useToast,
  Text,
  Stack,
} from "@chakra-ui/react";
import { useCreateCapitalEquipment } from "../../../api/capital_equipment";
import { useTeamOptions } from "../../../api/options";
import { useForm, Controller } from "react-hook-form";
import {
  LocationTreeKeyValue,
  LocationTreeValue,
  useTreeSelectInventoryLocations,
} from "../../../api/inventory_location";
import { initial } from "lodash";
import { TreeSelect, findSelectedOption } from "../../shared/TreeSelect";
import { useNavigate } from "react-router";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { CapitalEquipmentCategoryOptions } from "../utils";

type NewToolModalProps = {
  teamId?: number;
  isOpen: boolean;
  onClose: () => void;
};

type NewToolModalFormValuesType = {
  name: string;
  team_id: number | null;
  wafer_services_tool_id: string;
  category: string;
  inventory_location_id: number | null;
};

const defaultFormValues: NewToolModalFormValuesType = {
  name: "",
  team_id: null,
  inventory_location_id: null,
  wafer_services_tool_id: "",
  category: "",
};

export const NewCapitalEquipment = ({ teamId, isOpen, onClose }: NewToolModalProps) => {
  const teamOptions = useTeamOptions();
  const [locationSearch, setLocationSearch] = useState<string | undefined>();
  const [location, setLocation] = useState<LocationTreeValue | null>(null);
  const [pathToLocation, setPathToLocation] = useState<LocationTreeKeyValue[] | undefined>();
  const treeSelectInventoryLocationsQuery = useTreeSelectInventoryLocations(locationSearch);
  const [showPreview, setShowPreview] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm({ defaultValues: defaultFormValues });
  const formValues = watch();
  const disableSubmit =
    !formValues.name ||
    !formValues.team_id ||
    !formValues.category ||
    !formValues.inventory_location_id;
  const { mutateAsync: createTool, isLoading: isLoadingCreateTool } = useCreateCapitalEquipment();
  const handleClose = () => {
    reset();
    onClose();
  };
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const data = treeSelectInventoryLocationsQuery?.data;
    if (location && data) {
      const path = findSelectedOption(data, location);
      setPathToLocation(initial(path));
      setShowPreview(true);
    }
  }, [treeSelectInventoryLocationsQuery?.data, location]);

  useEffect(() => {
    if (teamId) {
      reset({ team_id: teamId });
    }
  }, [teamId]);

  const onSubmit = (data: NewToolModalFormValuesType) => {
    if (data.team_id) {
      createTool({
        team_id: Number(data.team_id),
        category: data.category,
        inventory_location_id: data.inventory_location_id,
        wafer_services_tool_id: data.wafer_services_tool_id,
        name: data.name,
      })
        .then((data) => {
          navigate(`/equipment/${data.id}/details`);
          toast({
            title: "Equipment created.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          handleClose();
        })
        .catch((error) => {
          console.error("Error creating equipment:", error);
          toast({
            title: "Error creating equipment.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <FormModal
      size={"2xl"}
      title="New Equipment"
      isOpen={isOpen}
      closeOnOverlayClick={false}
      submitButtonColorSchema="teal"
      submitButtonDisabled={disableSubmit}
      onClose={handleClose}
      isLoading={isLoadingCreateTool}
      handleSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%" gap={4}>
        <HStack width={"100%"}>
          <Box width={"50%"}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>Item Name</FormLabel>
                  <Input {...field} data-testid="capital-equipment-item-name-input" />
                </FormControl>
              )}
            />
          </Box>
          <Box width={"50%"}>
            <Controller
              name="team_id"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>Team</FormLabel>
                  <Select options={teamOptions} {...field} isDisabled={!!teamId} />
                </FormControl>
              )}
            />
          </Box>
        </HStack>
        <HStack>
          <Box width={"50%"}>
            <Controller
              name="wafer_services_tool_id"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <FormLabel>Wafer Services Tool ID</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            />
          </Box>
          <Box width={"50%"}>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>Category</FormLabel>
                  <Select
                    {...field}
                    options={CapitalEquipmentCategoryOptions}
                    placeholder="Choose"
                  />
                </FormControl>
              )}
            />
          </Box>
        </HStack>
        <Controller
          name="inventory_location_id"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Location</FormLabel>
              {showPreview ? (
                <Stack
                  onClick={() => setShowPreview(false)}
                  direction="row"
                  flexWrap="wrap"
                  px={4}
                  py={2}
                  border="1px"
                  borderRadius="md"
                  borderColor="chakra-border-color">
                  {pathToLocation?.map((location, idx) => {
                    const isLast = idx === pathToLocation?.length - 1;
                    return (
                      <Box key={`destination-${location.label}-${location.value?.id}`}>
                        <HStack>
                          <Text
                            color={isLast ? "teal.500" : "auto"}
                            fontWeight={isLast ? "semibold" : "normal"}>
                            {location.label}
                          </Text>
                          {idx < (pathToLocation?.length || 1) - 1 && <ArrowForwardIcon />}
                        </HStack>
                      </Box>
                    );
                  })}
                </Stack>
              ) : (
                <Box>
                  <TreeSelect
                    options={treeSelectInventoryLocationsQuery.data || []}
                    placeholder="Select Location"
                    isLoading={treeSelectInventoryLocationsQuery.isLoading}
                    onSearchChanged={(value) => {
                      setLocationSearch(value);
                    }}
                    defaultValue={location}
                    onChange={(value) => {
                      if (value) {
                        setLocation(value);
                        field.onChange(value.id);
                      }
                    }}
                  />
                </Box>
              )}
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};
