import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  VStack,
  Flex,
  Avatar,
  useColorModeValue,
  Box,
  Text,
  Link,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { RichTextEditor } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useUpdateTimelineEvent, useDeleteTimelineEvent } from "../../../api/timeline_events";
import { BsThreeDots } from "react-icons/bs";
import { useMinimalUsersQuery } from "../../../api/user";
import { useUserMentionOptions } from "../../../api/options";

export const subscriptionStatusColor = (status: string) => {
  switch (status) {
    case "approved":
      return "green";
    case "declined":
    case "needs_approval":
      return "red";
    default:
      "gray";
  }
};

export const subscriptionPaymentStatusColor = (status: string) => {
  switch (status) {
    case "paid":
      return "green";
    case "declined":
    case "needs_approval":
      return "red";
    case "pending":
      return "orange";
    default:
      "gray";
  }
};

export const TimelineEventContent = ({ timelineEvent, currentUser, onSuccessCallback }) => {
  const content = timelineEvent.rendered_content;
  return (
    <VStack align="start" flex="1">
      {timelineEvent.event_type !== "comment" ? (
        <Flex gap={1} align="center">
          {timelineEvent.user ? (
            <Link as={RouterLink} to={`/users/${timelineEvent.user.id}`}>
              <Avatar
                size="xs"
                name={timelineEvent.user.name}
                src={timelineEvent.user.picture_uri}
              />
            </Link>
          ) : null}

          <Text fontSize="xs" align="start">
            {content.map((part, index) => {
              switch (part.type) {
                case "user_name":
                  return timelineEvent.user ? (
                    <Link
                      key={`content-${index}`}
                      as={RouterLink}
                      to={`/users/${timelineEvent.id}`}
                      verticalAlign="middle"
                      fontWeight="semibold">
                      {part.content}
                    </Link>
                  ) : null;
                case "text":
                  // For text, check if a specific style is needed
                  if (part.style === "bold") {
                    return (
                      <Box
                        as="span"
                        fontWeight="bold"
                        key={`content-${index}`}
                        verticalAlign="middle">
                        {part.content}{" "}
                      </Box>
                    );
                  } else if (part.style === "italic") {
                    return (
                      <Box
                        as="i"
                        key={`content-${index}`}
                        verticalAlign="middle"
                        color={useColorModeValue("gray.500", "gray.400")}>
                        {part.content}{" "}
                      </Box>
                    );
                  } else {
                    return (
                      <Box as="span" key={`content-${index}`} verticalAlign="middle">
                        {part.content}{" "}
                      </Box>
                    );
                  }
                default:
                  return null;
              }
            })}
            <Box as="span" verticalAlign="middle">
              on {timelineEvent.created_at.toFormat("LLL dd")}
            </Box>
          </Text>
        </Flex>
      ) : (
        <TimelineComment
          timelineEvent={timelineEvent}
          currentUser={currentUser}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </VStack>
  );
};

const TimelineComment = ({ timelineEvent, currentUser, onSuccessCallback }) => {
  const [edit, setEdit] = useState(false);
  const now = DateTime.now();
  const createdDateFromISO = DateTime.fromISO(timelineEvent.created_at);
  const diffText = createdDateFromISO.toRelative({ base: now, style: "long" });
  const minimalUsers = useUserMentionOptions();
  const { mutateAsync: updateTimelineEvent } = useUpdateTimelineEvent(onSuccessCallback);
  const { mutate: deleteTimelineEvent } = useDeleteTimelineEvent(onSuccessCallback);
  return (
    <Box w="100%" maxW="3xl">
      <Box
        bg={useColorModeValue("gray.50", "gray.400")}
        p={2}
        mb={-2.5}
        borderTopRadius="lg"
        w="100%"
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.600")}
        borderBottom="0px"
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Text fontWeight="medium" color="gray.600" fontSize="sm">
          <Link href={`/users/${timelineEvent.user.id}`}>
            <Box as="span" fontWeight="semibold" color="gray.800">
              {timelineEvent.user.name}
            </Box>
          </Link>{" "}
          commented | {diffText}
        </Text>
        {currentUser?.id === timelineEvent.user.id && (
          <CommentActions
            timelineEvent={timelineEvent}
            currentUser={currentUser}
            setEdit={setEdit}
            deleteTimelineEvent={deleteTimelineEvent}
          />
        )}
      </Box>
      <Box width="100%">
        <RichTextEditor
          mentionMap={{ "@": minimalUsers }}
          width="100%"
          editable={edit}
          defaultValue={timelineEvent.rendered_content[0].content}
          onSave={(rich, plainText) => {
            return updateTimelineEvent({
              id: timelineEvent.id,
              content: rich ?? "",
              slack_message: plainText ?? "",
            })
              .then(() => {
                setEdit(false);
                return true;
              })
              .catch((error) => {
                console.error(error);
                return false;
              });
          }}
        />
      </Box>
    </Box>
  );
};

const CommentActions = ({ timelineEvent, currentUser, setEdit, deleteTimelineEvent }) => (
  <Menu placement="bottom-end">
    <MenuButton
      as={IconButton}
      aria-label="Options"
      icon={<BsThreeDots size={20} />}
      size="xs"
      color={useColorModeValue("gray.400", "gray.700")}
      variant="ghost"
    />
    <MenuList alignItems="center">
      <MenuItem
        icon={<EditIcon />}
        onClick={() => {
          setEdit(true);
        }}>
        Edit comment
      </MenuItem>
      <MenuItem
        icon={<DeleteIcon color="red.500" />}
        onClick={() => {
          deleteTimelineEvent(timelineEvent.id);
        }}>
        Delete comment
      </MenuItem>
    </MenuList>
  </Menu>
);
