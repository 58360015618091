import { FormControl, FormLabel, useDisclosure } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMoveBudgetItemAssociation } from "../../../api/budget_items";
import { BudgetKey } from "../../../api/budget_tree";
import { BudgetSelect } from "../../Purchasing/BudgetSelect";

type FormValuesType = {
  destination_budget_id: BudgetKey | null;
};

const defaultFormValues = {
  destination_budget_id: null,
};

export type MoveBudgetItemModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  control: any;
  setValue: any;
  isLoading: boolean;
};

export const useMoveBudgetItemModal = (
  selectedBudgetItemIds: number[],
  setSelectedBudgetItemIds: React.Dispatch<React.SetStateAction<number[]>>
): MoveBudgetItemModalProps & { onOpen: () => void } => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setValue, control, watch } = useForm<FormValuesType>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });
  const { mutateAsync: moveBudgetItemAssociation, isLoading } = useMoveBudgetItemAssociation();

  const destinationBudgetId = watch("destination_budget_id");
  const handleSubmit = () => {
    if (!destinationBudgetId) {
      return;
    }

    moveBudgetItemAssociation({
      destinationBudgetId,
      movingBudgetItemIds: selectedBudgetItemIds,
    })
      .then(() => {
        onClose();
        setSelectedBudgetItemIds([]);
      })
      .catch((error) => {
        console.error("An error occurred during the move operation:", error);
      });
  };

  return {
    isOpen,
    onClose,
    control,
    setValue,
    onOpen,
    onSubmit: handleSubmit,
    isLoading,
  };
};

export const MoveBudgetItemModal = ({
  isOpen,
  onClose,
  onSubmit,
  control,
  setValue,
  isLoading,
}: MoveBudgetItemModalProps) => {
  return (
    <FormModal
      title="Move Budget Items"
      closeOnOverlayClick={false}
      submitButtonColorSchema="teal"
      submitButtonTitle="Submit"
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={onSubmit}>
      <Controller
        name="destination_budget_id"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <FormLabel>Budget</FormLabel>
            <BudgetSelect
              emptyOptionText="Move Here"
              onChange={(budgetId) => {
                setValue("destination_budget_id", budgetId);
              }}
              budgetId={field.value || null}
            />
          </FormControl>
        )}
      />
    </FormModal>
  );
};
