import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { SearchParams, indexParams, searchParams } from "./collection_types";
import { createSearchResponseSchema } from "./shared";
import { budgetItemSummarySchema } from "./budget_items";
import { zodParse } from "./zodParse";
import { blobFileSchema } from "./blob_files";
import { capitalEquipmentPartSchema } from "./capital_equipment_part";
import { userMinimalSchema } from "./user";
import { timelineEventSchema } from "./timeline_events";
import { capitalEquipmentUserSchema } from "./capital_equipment_user";
import { vendorSchema } from "./inventory_vendor";
import { barcodeSchema } from "./barcode";

export const CAPITAL_EQUIPMENTS_BASE_URL = "/capital_equipments";

export const capitalEquipmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number().nullable(),
  wafer_services_tool_id: z.string().nullable(),
  notes: z.string().nullable(),
  manufacturer_name: z.string().nullable(),
  manufacturer_part_number: z.string().nullable(),
  manufacturer_model: z.string().nullable(),
  serial_number: z.string().nullable(),
  power_requirements: z.string().nullable(),
  gas_requirements: z.string().nullable(),
  power_required: z.boolean(),
  exhaust_required: z.boolean(),
  compressed_air_required: z.boolean(),
  distilled_water_required: z.boolean(),
  chilled_water_required: z.boolean(),
  drain_required: z.boolean(),
  is_archived: z.boolean(),
  maintenance_schedule: z.string().nullable(),
  calibration_schedule: z.string().nullable(),
  reminders: z.boolean().nullable(),
  reminder_days_prior: z.number().nullable(),
  status: z.string(),
  previous_active_capital_equipment_id: z.number().nullable(),
  next_active_capital_equipment_id: z.number().nullable(),
  asset_id: z.string().nullable(),
  category: z.string().nullable(),
  inventory_location_id: z.number().nullable(),
  next_calibration_date: z.string().nullable(),
  next_maintenance_date: z.string().nullable(),
  is_calibration_overdue: z.boolean(),
  is_maintenance_overdue: z.boolean(),
  archived_at: z.string().nullable(),
  barcode: barcodeSchema.nullable(),
});

export const capitalEquipmentCreateSchema = z.object({
  id: z.number(),
  name: z.string(),
  category: z.string(),
  team_id: z.number().nullable(),
  wafer_services_tool_id: z.string().nullable(),
  inventory_location_id: z.number().nullable(),
});

export const capitalEquipmentShowSchema = capitalEquipmentSchema
  .omit({
    team_id: true,
  })
  .extend({
    team: z
      .object({
        id: z.number().nullable(),
        name: z.string().nullable(),
      })
      .nullable(),
    budget_id: z.number(),
    can_be_deleted: z.boolean(),
    summary: budgetItemSummarySchema.optional(),
    uploaded_files: z.array(blobFileSchema),
    vendors: z.array(vendorSchema),
    capital_equipment_users: z.array(
      z.object({
        id: z.number(),
        capital_equipment_id: z.number(),
        user: userMinimalSchema,
      })
    ),
    can_act_as_lead: z.boolean(),
  });

const capitalEquipmentSearchResultSchema = createSearchResponseSchema(capitalEquipmentShowSchema);

export const bulkMoveCapitalEquipmentSchema = z.object({
  capital_equipment_ids: z.array(z.number()),
  inventory_location_id: z.number(),
});

export type CapitalEquipmentData = z.infer<typeof capitalEquipmentSchema>;
export type CapitalEquipmentCreateData = z.infer<typeof capitalEquipmentCreateSchema>;
export type CapitalEquipmentShowData = z.infer<typeof capitalEquipmentShowSchema>;
export type CapitalEquipmentCreateParams = Omit<CapitalEquipmentCreateData, "id">;
export type BulkMoveCapitalEquipmentData = z.infer<typeof bulkMoveCapitalEquipmentSchema>;

export type CapitalEquipmentSearchResponse = z.infer<typeof capitalEquipmentSearchResultSchema>;

/** queries */
export const getCapitalEquipment = async (id: number | null | undefined) => {
  const result = await api.get(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}`);
  return zodParse(capitalEquipmentShowSchema, result.data);
};

export const getCapitalEquipmentUsers = async (id: number | null | undefined) => {
  const result = await api.get(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}/users`);
  return zodParse(capitalEquipmentUserSchema.array(), result.data);
};

export const getCapitalEquipments = async () => {
  const result = await api.get(CAPITAL_EQUIPMENTS_BASE_URL);
  return zodParse(capitalEquipmentShowSchema.array(), result.data);
};

export const getCapitalEquipmentParts = async (id: number) => {
  const result = await api.get(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}/parts`);
  return zodParse(z.array(capitalEquipmentPartSchema), result.data);
};

export const getCapitalEquipmentEvents = async (id: number) => {
  const result = await api.get(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}/events`);
  return zodParse(z.array(timelineEventSchema), result.data);
};

export const searchCapitalEquipments = async ({
  aggs,
  filters,
  bodyOptions,
  pagination,
  order,
  term,
}: SearchParams) => {
  const path = [CAPITAL_EQUIPMENTS_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ aggs, filters, term, bodyOptions });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(capitalEquipmentSearchResultSchema, result.data);
};

export const createCapitalEquipment = async (capital_equipment: CapitalEquipmentCreateParams) => {
  const result = await api.post(CAPITAL_EQUIPMENTS_BASE_URL, { capital_equipment });
  return result.data;
};

export const updateCapitalEquipment = async (capital_equipment: Partial<CapitalEquipmentData>) => {
  await api.put(`${CAPITAL_EQUIPMENTS_BASE_URL}/${capital_equipment.id}`, { capital_equipment });
};

export const archiveCapitalEquipment = async (id: number) => {
  await api.put(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}/archive`);
};

export const unarchiveCapitalEquipment = async (id: number) => {
  await api.put(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}/unarchive`);
};

export const bulkArchiveCapitalEquipment = async (ids: number[]) => {
  await api.post(`${CAPITAL_EQUIPMENTS_BASE_URL}/bulk_archive`, { capital_equipment_ids: ids });
};

export const bulkMoveCapitalEquipment = async (bulk_move: BulkMoveCapitalEquipmentData) => {
  await api.post(`${CAPITAL_EQUIPMENTS_BASE_URL}/bulk_move`, { bulk_move });
};

export const updateCapitalEquipmentLocation = async (
  capital_equipment: Partial<CapitalEquipmentData>
) => {
  await api.put(`${CAPITAL_EQUIPMENTS_BASE_URL}/${capital_equipment.id}/update_location`, {
    capital_equipment,
  });
};

export const deleteCapitalEquipment = async (id: number) => {
  await api.delete(`${CAPITAL_EQUIPMENTS_BASE_URL}/${id}`);
  return id;
};

/** hooks */

export const invalidateCapitalEquipments = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [CAPITAL_EQUIPMENTS_BASE_URL];
  if (id) queryKey.push(id);
  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useGetCapitalEquipments = () => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL],
    queryFn: () => getCapitalEquipments(),
  });
};

export const useGetCapitalEquipmentUsers = (id: number) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, id, "users"],
    queryFn: () => getCapitalEquipmentUsers(id),
  });
};

export const useGetCapitalEquipmentEvents = (id: number) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, id, "events"],
    queryFn: () => getCapitalEquipmentEvents(id),
  });
};

export const useGetCapitalEquipmentParts = (id: number) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, id, "parts"],
    queryFn: () => getCapitalEquipmentParts(id),
  });
};

export const useSearchCapitalEquipments = (params: SearchParams) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, params],
    queryFn: () => searchCapitalEquipments(params),
  });
};

export const useGetCapitalEquipment = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [CAPITAL_EQUIPMENTS_BASE_URL, id],
    queryFn: () => getCapitalEquipment(id),
    enabled: !!id,
  });
};

export const useCreateCapitalEquipment = () => {
  return useMutation({
    mutationFn: createCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(),
  });
};

export const useUpdateCapitalEquipment = (id?: number) => {
  return useMutation({
    mutationFn: updateCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useArchiveCapitalEquipment = (id?: number) => {
  return useMutation({
    mutationFn: archiveCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useUnarchiveCapitalEquipment = (id?: number) => {
  return useMutation({
    mutationFn: unarchiveCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useUpdateCapitalEquipmentLocation = (id?: number) => {
  return useMutation({
    mutationFn: updateCapitalEquipmentLocation,
    onSuccess: invalidateCapitalEquipments(id),
  });
};

export const useBulkArchiveCapitalEquipment = () => {
  return useMutation({
    mutationFn: bulkArchiveCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(),
  });
};

export const useBulkMoveCapitalEquipment = () => {
  return useMutation({
    mutationFn: bulkMoveCapitalEquipment,
    onSuccess: invalidateCapitalEquipments(),
  });
};

export const useDeleteCapitalEquipment = () => {
  return useMutation({
    mutationFn: deleteCapitalEquipment,
  });
};
