import {
  Box,
  HStack,
  Icon,
  Link,
  Progress,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Collection, Column, RecordLink } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ContractShowData } from "../../api/contracts";

import { MdOutlineStorefront } from "react-icons/md";
import { LinkifyText } from "./util";

import { MoneyText } from "../MoneyText";

export const ContractsTab = ({
  data,
  isLoading,
  pagination,
  onPagination,
  order,
  onOrder,
  location,
}) => {
  const textColor = useColorModeValue("gray.600", "gray.400");
  const columns = useMemo(
    (): Column<ContractShowData>[] => [
      {
        label: "ID",
        weight: 0.5,
        orderOptions: { orderKey: "id" },
        render: (contract) => <Text>{contract.id}</Text>,
      },
      {
        label: "Contract",
        weight: 1.5,
        orderOptions: { orderKey: "name" },
        render: (contract) => (
          <RecordLink
            maxWidth="18ch"
            link={`/services/contracts/${contract.id}`}
            identifier={`${contract.name}`}
            size="small"
          />
        ),
      },
      {
        label: "Vendor",
        orderOptions: { orderKey: "vendor_name" },
        render: (contract) => (
          <RecordLink
            maxWidth="15ch"
            type=""
            identifier={contract.vendor.name}
            link={`/services/vendors/${contract.vendor.id}`}
            icon={
              <Box ml={3} mt={1}>
                <Icon as={MdOutlineStorefront} />
              </Box>
            }
          />
        ),
      },

      {
        label: "Details",
        orderOptions: { orderKey: "description" },
        render: (contract) => (
          <VStack align="start">
            <Text maxHeight={["10ch", "10ch", "5ch", "5ch", "5ch"]} isTruncated={true}>
              <LinkifyText
                text={contract.description || ""}
                maxWidth={["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"]}
                linkProps={{
                  maxWidth: ["50ch", "20ch", "20ch", "30ch", "30ch", "40ch"],
                }}
                isTruncated={true}
              />
            </Text>
          </VStack>
        ),
      },
      {
        label: "Billing Period",
        orderOptions: { orderKey: "billing_period" },
        render: (contract) => <Text>{contract.billing_period ?? "-"}</Text>,
      },
      {
        label: "Last Payment",
        render: (contract) => (
          <Text>
            <MoneyText money={contract.last_payment?.amount} fontWeight="semibold" />
            <Text fontSize="xs" color={textColor}>
              {contract.last_payment?.paid_at?.toFormat("MMM dd, yyyy")}
            </Text>
          </Text>
        ),
      },
      {
        label: "Total Amount Paid",
        render: (contract) => <MoneyText money={contract.total_paid} fontWeight="semibold" />,
      },
    ],
    [data]
  );

  return (
    <Box maxW="100%" overflowX="auto">
      {location === "contracts" && (
        <Stack
          flexDir={{ base: "column", md: "column", lg: "row" }}
          justify="space-between"
          spacing={[6, 4]}
          mb={8}>
          <HStack
            py={3}
            px={6}
            flex="1"
            border="1px"
            borderColor="chakra-border-color"
            borderRadius="md"
            bg={useColorModeValue("gray.50", "gray.700")}>
            <Stat>
              <StatLabel>Active</StatLabel>
              <StatNumber>{data?.pagination.total}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Archived</StatLabel>
              <StatNumber>{data?.archived_contract_count}</StatNumber>
            </Stat>
            <Link as={RouterLink} to="/services/contracts/archive" color="teal.500">
              View Archive
            </Link>
          </HStack>
        </Stack>
      )}
      <Collection
        columns={columns}
        items={data?.results || []}
        isLoading={isLoading}
        onPagination={onPagination}
        pagination={data?.pagination || pagination}
        order={order}
        onOrder={onOrder}
      />
    </Box>
  );
};
