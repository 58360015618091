import {
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { BudgetGroupShowData, useGetBudgetGroup } from "../../../../../api/budget_groups";
import { useBudgetGroupOptions } from "../../../../../api/options";
import { BudgetIndexItemData, useMoveBudgets } from "../../../../../api/budget";
import { BudgetGroupStats } from "./BudgetGroupStats";

type FormValues = {
  budget_group_id: number | null;
};

export const useMoveBudgetsModal = ({ selectedBudgets, setSelectedBudgets }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { control, watch, reset, handleSubmit } = useForm<FormValues>();
  const toast = useToast();
  const { mutate: moveBudgets, isLoading } = useMoveBudgets();
  const formValues = watch();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: FormValues) => {
    const budgetIds = selectedBudgets.map((budget) => budget.id);
    moveBudgets(
      {
        budgetIds: budgetIds,
        newBudgetGroupId: data.budget_group_id!,
      },
      {
        onSuccess: () => {
          handleClose();
          setSelectedBudgets([]);
          toast({
            title: "Succcess",
            description: `Successfully moved budgets`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error",
            description: `Failed to move budgets`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      }
    );
  };

  return {
    control,
    isLoading,
    handleSubmit: handleSubmit(onSubmit),
    isOpen,
    onOpen,
    formValues,
    handleClose,
  };
};

type MoveBudgetModalProps = {
  selectedBudgets: BudgetIndexItemData[];
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  formValues: FormValues;
  control: Control<FormValues, any>;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  currentBudgetGroup: BudgetGroupShowData;
};

export const MoveBudgetModal = ({
  selectedBudgets,
  isOpen,
  handleClose,
  formValues,
  isLoading: isLoadingMoveBudgets,
  control,
  handleSubmit,
  currentBudgetGroup,
}: MoveBudgetModalProps) => {
  const budgetGroupOptions = useBudgetGroupOptions([currentBudgetGroup.id]);

  const { data: budgetGroup, isLoading, isError } = useGetBudgetGroup(formValues.budget_group_id);

  const disableSubmitButton = !formValues.budget_group_id;

  const budgetGroupNames =
    selectedBudgets.length > 1
      ? selectedBudgets.map((budget) => budget.budgetable.name).join(", ")
      : selectedBudgets.length === 1
      ? selectedBudgets[0].budgetable.name
      : "";

  return (
    <FormModal
      title="Move Budget"
      submitButtonColorSchema="teal"
      closeOnOverlayClick={false}
      submitButtonTitle="Submit"
      size="lg"
      isLoading={isLoadingMoveBudgets}
      isOpen={isOpen}
      onClose={handleClose}
      handleSubmit={handleSubmit}
      submitButtonDisabled={disableSubmitButton}>
      <Flex direction="column" w="100%" gap={4}>
        <Text>Move {budgetGroupNames} to another budget group</Text>
        <Controller
          name="budget_group_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Destination Budget Group</FormLabel>
              <Select {...field} options={budgetGroupOptions} />
            </FormControl>
          )}
        />

        {formValues.budget_group_id ? (
          isLoading ? (
            <Spinner />
          ) : isError ? (
            <Text>Error loading budget group summary </Text>
          ) : (
            <BudgetGroupStats summary={budgetGroup.summary} />
          )
        ) : null}
      </Flex>
    </FormModal>
  );
};
