import React, { useMemo } from "react";
import { Collection, Column, Order, Pagination, RecordLink } from "@sciencecorp/helix-components";
import {
  Avatar,
  Box,
  Button,
  Text,
  HStack,
  Icon,
  useColorModeValue,
  Tag,
  Flex,
  Stack,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { titleize, humanize } from "inflection";
import { BiCube } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { inventoryStatusColor } from "../util";
import { EmptyState } from "../../shared/EmptyState";
import { InventoryIndexData } from "../../../api/inventory";
import { SearchResponse } from "../../../api/shared";
import { PreviewUserSelect } from "../../Users/shared/UserSelect";

export type InventoryProps = {
  isLoading: boolean;
  data: SearchResponse<InventoryIndexData> | undefined;
  onPagination: (pagination: Pagination) => void;
  pagination: Pagination;
  order: Order;
  onOrder: (order: Order) => void;
};

export const Inventories = ({
  isLoading,
  data,
  onPagination,
  pagination,
  order,
  onOrder,
}: InventoryProps) => {
  const navigate = useNavigate();
  const archivedCount =
    data?.aggregations?.is_archived.buckets.find((bucket) => bucket.key === 1)?.doc_count || 0;

  const columns: Column<InventoryIndexData>[] = useMemo(
    () => [
      {
        label: "Item Name",
        orderOptions: { orderKey: "name" },
        render: (inventory) => (
          <RecordLink
            type=""
            icon={<Icon as={BiCube} mt={1} />}
            identifier={inventory.name}
            link={`/inventory/items/${inventory.id}/details`}
          />
        ),
      },
      {
        label: "Category",
        render: (inventory) => titleize(humanize(inventory.category)),
      },

      {
        label: "Location",
        render: (inventory) => {
          return inventory.inventory_locations?.map((location) => location.name).join(", ");
        },
      },
      {
        label: "Item ID/SKU",
        render: (inventory) => inventory.sku || "N/A",
      },

      {
        label: "Stock",
        render: (inventory) =>
          `${inventory.is_consumable ? inventory.total_quantity_stock : inventory.stock} ${
            inventory.unit_of_measurement || "units"
          }`,
      },
      {
        label: "Status",
        render: (inventory) => (
          <Tag
            colorScheme={inventoryStatusColor(String(inventory.status))}
            flexShrink={0}
            whiteSpace="nowrap"
            size={["sm", "md"]}>
            {titleize(humanize(String(inventory.status)))}
          </Tag>
        ),
      },
      {
        label: "Owner",
        render: (inventory) => (
          <HStack>
            {inventory.users && inventory.users.length > 0 ? (
              <PreviewUserSelect
                addedUsers={inventory.users}
                avatarGroupSize={1}
                additionalUsersTitle="Owners"
              />
            ) : (
              <Text fontStyle="italic" color={useColorModeValue("gray.400", "gray.200")}>
                No owners associated
              </Text>
            )}
          </HStack>
        ),
      },
    ],
    [data]
  );

  return (
    <Flex direction="column" gap={4}>
      <Stack py={2} gap={4} width="100%">
        <StatGroup display="flex" flexDirection={{ base: "column", md: "row" }} gap={6}>
          <Stat
            border="1px"
            borderColor="chakra-border-color"
            borderRadius="md"
            px={6}
            py={4}
            flex="2">
            <StatLabel>Total Inventory Items</StatLabel>
            <StatNumber>{data?.pagination.total || "-"}</StatNumber>
          </Stat>
          <Stat
            border="1px"
            backgroundColor={useColorModeValue("gray.100", "gray.700")}
            borderColor="chakra-border-color"
            borderRadius="md"
            px={6}
            py={4}
            justifyContent={"space-between"}>
            <HStack justifyContent={"space-between"}>
              <VStack align="start" gap={0}>
                <StatLabel>Archived Inventory Items</StatLabel>
                <StatNumber>{archivedCount}</StatNumber>
              </VStack>
              <Button
                size="sm"
                colorScheme="teal"
                variant="ghost"
                onClick={() => navigate("/inventory/archived")}>
                View
              </Button>
            </HStack>
          </Stat>
        </StatGroup>
        {isLoading || (data?.results || []).length > 0 ? (
          <Box w="100%" overflowX="scroll">
            <Collection
              columns={columns}
              items={data?.results || []}
              pagination={data?.pagination || pagination}
              isLoading={isLoading}
              order={order}
              onPagination={onPagination}
              onOrder={onOrder}
            />
          </Box>
        ) : (
          <EmptyState title="There are no inventory items" size="xs" />
        )}
      </Stack>
    </Flex>
  );
};
