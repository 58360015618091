import { Text } from "@chakra-ui/react";
import { RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import { useSearchContracts } from "../../../../../../api/contracts";
import { MoneyText } from "../../../../../MoneyText";
import { buildTabComponent } from "../../../../../TabbedTable";

export const ContractTab = buildTabComponent({
  label: "Contracts",
  columns: [
    {
      label: "Contract",
      orderOptions: { orderKey: "id" },
      render: (contract) => (
        <RecordLink
          type=""
          identifier={`#${contract.id}`}
          link={`/services/contracts/${contract.id}`}
        />
      ),
    },
    {
      label: "Vendor",
      orderOptions: { orderKey: "vendor_name" },
      render: (contract) => (
        <RecordLink
          type=""
          identifier={contract.vendor.name || ""}
          maxWidth="15ch"
          link={`/services/vendors/${contract.vendor.id}`}
        />
      ),
    },
    {
      label: "Billing Period",
      render: (contract) => <Text>{contract.billing_period ?? "-"}</Text>,
    },
    {
      label: "Total Paid",
      render: (contract) => (
        <Text>
          <MoneyText as="span" money={contract.total_paid} formatOptions={{ compact: "never" }} />
        </Text>
      ),
    },
    {
      label: "Total Cost",
      render: (contract) => (
        <Text>
          <MoneyText as="span" money={contract.total_cost} formatOptions={{ compact: "never" }} />
        </Text>
      ),
    },
  ],
  query: useSearchContracts,
});
