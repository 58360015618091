import React, { useState, useMemo } from "react";
import {
  Box,
  VStack,
  Text,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  Icon,
  IconButton,
  useDisclosure,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  CollectionPage,
  RecordLink,
  buildFacets,
  useCollection,
  ConfirmationModal,
  Column,
} from "@sciencecorp/helix-components";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { VendorIndexItemData, useSearchVendors, useUpdateVendor } from "../../../api/vendor";
import { BiStore } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiUndo } from "react-icons/ci";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";

export const VendorArchive = () => {
  const { facets, onFacets, filters, pagination, onPagination, order, onOrder } = useCollection();
  const { search, debouncedSearch } = useDebouncedSearch();
  const { mutate: updateVendor } = useUpdateVendor();
  const [activeVendor, setActiveVendor] = useState<VendorIndexItemData | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading } = useSearchVendors({
    term: "*" || search,
    pagination,
    order,
    filters: { ...filters, archived_at: { not: [null] } },
  });

  const columns: Column<VendorIndexItemData>[] = useMemo(
    () => [
      {
        label: "Vendor",
        orderOptions: { orderKey: "name" },
        render: (vendor) => (
          <RecordLink
            link={`/services/vendors/${vendor.id}`}
            identifier={`${vendor.name}`}
            size="small"
            icon={
              <Box ml={3}>
                <BiStore />
              </Box>
            }
          />
        ),
      },
      {
        label: "Address",
        render: (vendor) =>
          vendor.address ? (
            vendor.address
          ) : (
            <Text as="i" opacity="0.5">
              Unknown
            </Text>
          ),
      },
      {
        label: "Archived At",
        render: (vendor) =>
          vendor.archived_at && DateTime.fromISO(vendor.archived_at).toFormat("LLL dd yyyy"),
      },
      {
        label: "",
        render: (vendor) => (
          <ActionsMenu vendor={vendor} onOpen={onOpen} setActiveVendor={setActiveVendor} />
        ),
      },
    ],
    [data]
  );

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  return (
    <VStack width="100%" align="start">
      <Breadcrumb>
        <BreadcrumbItem color="teal">
          <BreadcrumbLink as={RouterLink} to="/services/vendors">
            Vendors
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Archive</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="lg" mb={4}>
        Archive
      </Heading>
      <Box width="100%">
        <CollectionPage
          columns={columns}
          items={data?.results || []}
          isLoading={isLoading}
          pagination={pagination}
          facetsProps={{
            debouncedSearch: debouncedSearch,
            search: true,
            background: true,
          }}
          onPagination={onPagination}
          order={order}
          onOrder={onOrder}
          facets={populatedFacets}
          onFacets={onFacets}
        />
      </Box>
      {activeVendor && (
        <ConfirmationModal
          colorScheme="teal"
          isOpen={isOpen}
          onClose={onClose}
          header="Restore Vendor"
          children="Restoring this vendor will place it back on the vendors page."
          confirmText="Continue"
          onClick={() => updateVendor({ id: activeVendor.id, archived_at: null })}
        />
      )}
    </VStack>
  );
};

export const ActionsMenu = ({ vendor, onOpen, setActiveVendor }) => (
  <Menu>
    <MenuButton
      as={IconButton}
      icon={<Icon as={BsThreeDotsVertical} />}
      variant="ghost"
      size="sm"
    />
    <MenuList fontSize="md">
      <MenuItem
        gap={3}
        onClick={() => {
          setActiveVendor(vendor);
          onOpen();
        }}>
        <Icon as={CiUndo} />
        Restore
      </MenuItem>
    </MenuList>
  </Menu>
);
