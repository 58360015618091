import { useToast, VStack, FormControl, FormLabel } from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import { useVendorOptions } from "../../../../api/options";
import { useMergeVendor, VendorShowData } from "../../../../api/vendor";

type MergeVendorFormValues = {
  targetVendorId: number | null;
};

type MergeVendorModalProps = {
  vendor: VendorShowData;
  isOpen: boolean;
  onClose: () => void;
};

export const MergeVendorModal = ({ vendor, isOpen, onClose }: MergeVendorModalProps) => {
  const vendorOptions = useVendorOptions({ excludeIds: [vendor.id] });
  const { mutateAsync: mergeVendor, isLoading: isMerging } = useMergeVendor();
  const defaultFormValues: MergeVendorFormValues = { targetVendorId: null };
  const toast = useToast();
  const navigation = useNavigate();
  const { control, watch } = useForm<MergeVendorFormValues>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });
  const isLoading = vendorOptions.length == 0 || isMerging;

  const onSubmit = () => {
    const formData = watch();
    if (formData.targetVendorId !== null) {
      mergeVendor({ sourceVendorId: vendor.id, targetVendorId: formData.targetVendorId })
        .then(() => {
          navigation(`/services/vendors/${formData.targetVendorId}`);
          toast({ status: "success", title: `Successfully consolidated ${vendor.name}!` });
          onClose();
        })
        .catch(() => {
          toast({ status: "error", title: "Failed to merge vendor" });
        });
    }
  };

  return (
    <FormModal
      title="Merge Vendor"
      submitButtonColorSchema="teal"
      submitButtonTitle="Submit"
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      submitButtonDisabled={watch("targetVendorId") === null || isMerging}
      handleSubmit={onSubmit}>
      <VStack width="100%" align="start" spacing={5}>
        <Controller
          name="targetVendorId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <FormLabel>
                Transfer all records from <strong>{vendor.name}</strong> to:
              </FormLabel>
              <Select {...field} options={vendorOptions} />
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};
